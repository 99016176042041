import {date_connexion, partie, socket} from "../model";

/**
 * Authentifie une équipe
 */
export function Auth() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get("token")) return;
    let code = urlParams.get("code");
    let partieId = +urlParams.get("idPartie");
    if (code === null || code.length === 0)
        code = localStorage.getItem("code");
    if (partieId === 0 || partieId.length === 0) {
        partieId = +localStorage.getItem("idPartie");
    }
    partie.id = partieId;
    socket.emit("auth", {code, partieId, date: date_connexion});
}