import {ReponseQCM} from "./ReponseQCM";
import {ReponsePhoto} from "./ReponsePhoto";
import {ReponseVideo} from "./ReponseVideo";
import {ReponseLibre} from "./ReponseLibre";
import {completed, emit} from "../../model";
import s from "./Reponse.module.css";
import {Carousel} from "../../Carousel";

/**
 * Affiche l'interface de réponse propre à la tâche (en fonction de son typeReponse)
 */
export function Reponse(props) {

    if ((completed.has(props.task.id) && (completed.get(props.task.id).juste || completed.get(props.task.id).nombre >= props.task.nbReponse)))
        return (<div> {localStorage.getItem("language") === "fr" ? "Vous avez déjà fini cette tâche" : "You already completed this task"} </div>);


    const close = () => {
        props.close();
        emit("confettis", false);
    }
    switch (props.task.typeReponse) {
        //QCM
        case 0:
            return <ReponseQCM close={close} task={props.task}/>
        //Photo
        case 2:
            return <ReponsePhoto close={close} task={props.task}/>
        //Vidéo
        case 3:
            return <ReponseVideo close={close} task={props.task}/>
        //Default et case 1 : réponse libre, sous forme de texte
        default:
            return <ReponseLibre close={close} task={props.task}/>
    }
}

/**
 * Affiche la popup après avoir répondu (avec les fichiers de réponse de la tâche)
 * @param state
 * @param props
 * @return {JSX.Element}
 */
export function popup({state, props}){
    return (
        <div onClick={(e) => {e.stopPropagation()}} className={s.popup}>
            <h1>{state.title}</h1>
            <div><Carousel _style={{height: "max(200px, 30%)", width: "min(300px, 80vh)"}} liens={props.task.liensFichiersReponse} /></div>
            {state.text}
            <button className={s.popup_btn} onClick={() => {props.close(); emit("confettis", false)}}>{localStorage.getItem("language") === "fr" ? "Retour à la liste" : "Back to the list"}</button>
        </div>
    );
}