import {Message} from "./Message";

export function MessageList({messages}) {
    if (!messages) return null;
    return (
        <>
            {
                messages.map((m, index) => {
                    return (<Message key={index} message={m}/>);
                })
            }
        </>
    );
}