import React from "react";
import style from "./Task.module.css";
import {faPersonWalking, faXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {PresentationTask} from "./PresentationTask";
import {QuestionTask} from "../QuestionTask";
import s from "../Menu/Popup/Popup.module.css";
import {emit} from "../model";

/**
 * Affiche une tâche
 */
export class Task extends React.Component {
    constructor(props) {
        super(props);
        this.state = {answering: false};
    }

    render() {
        return (
            <div className={style.full}>
                <button onClick={this.props.removeTask} className={style.close}><FontAwesomeIcon icon={faXmark} /></button>
                {this.state.answering ? <QuestionTask close={this.props.removeTask} task={this.props.task} back={() => {this.setState({answering: false})}}/> : <PresentationTask answer={() => {this.setState({answering: true})}} task={this.props.task}/>}
                {this.props.task.geolocalise && <button onClick={() => {
                    emit("close_list");
                    this.props.guide();
                    this.props.removeTask();
                }} className={s.itineraire}><FontAwesomeIcon icon={faPersonWalking}/></button>}
            </div>
        );
    }
}