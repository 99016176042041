import {Component} from "react";
import style from "../QuestionTask/QuestionTask.module.css";
import s from "./Joker.module.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleArrowLeft} from "@fortawesome/free-solid-svg-icons";

/**
 * Affiche un Joker
 */
export class Joker extends Component {
    render() {
        return (
            <div>
                <button className={style.back} onClick={this.props.close}><FontAwesomeIcon icon={faCircleArrowLeft}/></button>
                <h3 className={s.h3}>Joker</h3>
                <p className={s.p}>{this.props.joker.text} {this.props.joker.text} {this.props.joker.text} {this.props.joker.text} </p>
            </div>
        );
    }
}