import {Link} from "react-router-dom";
import {useRef} from "react";
import {emit} from "../model";

/**
 * Redirige l'utilisateur vers le lien demandé
 * @param lien
 * @return {JSX.Element}
 */
export function History({lien}) {
    const l = useRef();
    const i = setInterval(() => {
        if (l.current) {
            clearInterval(i);
            l.current.click();
            emit("redirected");
        }
    }, 50);
    return (<Link to={lien} ref={l} />);
}