import style from "./ReponseVideo.module.css";
import {answerPhotoVideo, emit} from "../../../model";
import Swal from "sweetalert2";
import {Jingle} from "../../../Jingle";
import {ReponseGeolocalisee} from "../ReponseGeolocalisee";
import {popup} from "../Reponse";

/**
 * Affiche l'interface de réponse vidéo
 */
export class ReponseVideo extends ReponseGeolocalisee {

    constructor(props) {
        super(props);
        let trad = this.props.task.traductions.find(traduction => traduction.language === localStorage.getItem("language"));
        if (!trad)
            trad = this.props.task.traductions.find(tr => tr.language === "en");
        if (!trad)
            trad = this.props.task.traductions[0];
        this.state = {lien: null, file: null, trad};
        this.handlePic = this.handlePic.bind(this);
    }

    componentWillUnmount() {
        window.removeEventListener("click", this.props.close);
    }

    /**
     * Envoie la vidéo prise
     * @param e {Event}
     */
    handlePic(e) {
        const lien = window.URL.createObjectURL(e.target.files[0]);
        this.setState({lien});
        const video = document.createElement("video");
        video.preload = 'metadata';
        video.src = lien;
        video.onloadedmetadata = () => {
            window.URL.revokeObjectURL(video.src);
            const duration = video.duration;
            // vérifie que la durée de la vidéo n'excède pas la durée maximum (spécifiée dans la tâche)
            if (this.props.task.maxLengthVideo && this.props.task.maxLengthVideo < duration) {
                Swal.fire({
                    icon: "warning",
                    title: localStorage.getItem("language") === 'fr' ?
                        "La vidéo est trop longue, elle ne doit pas dépasser " + this.props.task.maxLengthVideo + "s." :
                        "The video is too long, it must not last longer than " + this.props.task.maxLengthVideo + "s."
                });
                return
            }
            const form = new FormData();
            form.append("file", e.target.files[0]);
            if (answerPhotoVideo({task: this.props.task, answer: form})) {
                Jingle(true);
                emit("confettis", true);
                const title = localStorage.getItem("language") === "fr" ? "Réponse enregistrée" : "Answer saved";
                this.setState({repondu: true, title, text: this.state.trad.messageSuccess});
                setTimeout(() => window.addEventListener("click", this.props.close, 0));
            }
        }
    }

    render() {
        if (this.state.repondu) {
            return popup(this);
        }
        return (
            <div className={style.div}>
                {this.props.task.maxLengthVideo ? <p>{localStorage.getItem("language") === 'fr' ?
                    "La vidéo ne doit pas dépasser " + this.props.task.maxLengthVideo + "s" :
                    "The video must not last longer than " + this.props.task.maxLengthVideo + "s"
                }</p> : ""}
                {this.state?.text ?? ""}
                <label>
                    <span
                        className={style.btn}>{localStorage.getItem("language") === 'fr' ? "Prendre une vidéo" : "Take a video"}</span>
                    <input onChange={this.handlePic} className={style.invisible} type={"file"} accept={"video/*"}
                           capture={"environment"}/>
                </label>
            </div>
        );
    }
}