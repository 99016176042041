import {Component} from "react";
import style from "./NavBar.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBars,
    faCircleQuestion,
    faGear,
    faMap,
    faMessage,
    faRankingStar,
    faXmark
} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {conversations, equipe, off, on} from "../model";

/**
 * Affiche la navbar (burger menu)
 */
export class NavBar extends Component {
    constructor(props) {
        super(props);
        this.state = {open: false};
        this.handleClickMenu = this.handleClickMenu.bind(this);
        this.handleWindowClick = this.handleWindowClick.bind(this);
        this.update = this.update.bind(this);
    }

    /**
     * Ouvre ou ferme la navbar
     * @param e
     */
    handleClickMenu(e) {
        e.stopPropagation();
        this.setState(state => ({open: !state.open}));
    }

    /**
     * ferme la navbar quand on clique à côté
     */
    handleWindowClick() {
        this.setState({open: false});
    }

    componentDidMount() {
        on("message", this.update);
        window.addEventListener("click", this.handleWindowClick);
    }

    componentWillUnmount() {
        off("message", this.update);
        window.removeEventListener('click', this.handleWindowClick);
    }

    update() {
        this.forceUpdate();
    }

    render() {
        /* L'utilisateur n'est pas connecté */
        if (!localStorage.getItem("code")) {
            return (
                <div>
                    <div className={style.barre + " " + (!!this.state.open ? style.barreOuverte : style.barreFermee)} id={"barre"} onClick={this.handleClickMenu}>
                        <button className={`${style.visible} ${style.burgerButton} ${style.button}`}>{this.state.open ? <FontAwesomeIcon icon={faXmark} /> : <FontAwesomeIcon icon={faBars} />}</button>
                        {/*Always appears under the burger menu button */}
                        <button disabled={true} className={style.button + " " + style.hidden}/>
                        {window.location.pathname.includes("/partie") ? "" : <Link to={"/partie"}><button className={`${style.button}`}><FontAwesomeIcon icon={faMap}/></button></Link>}
                        {window.location.pathname.includes( "/chat" ) ? "" : <Link to={"/chat/admin"}><button className={`${style.button}`}><FontAwesomeIcon icon={faMessage}/></button></Link>}
                        {window.location.pathname === "/help" ? "" : <Link to={"/help"}><button className={`${style.button}`}><FontAwesomeIcon icon={faCircleQuestion}/></button></Link>}
                        {window.location.pathname === "/settings" ? "" : <Link to={"/settings"}><button className={`${style.button}`}><FontAwesomeIcon icon={faGear}/></button></Link>}
                    </div>
                </div>
            );
        }
        // true si l'équipe n'a pas lu tous les messages
        let message = false;
        for (const c in conversations) {
            try {
                if (conversations[c].seen[`_${equipe.id}`] < conversations[c].messages.length) {
                    message = true;
                    break;
                }
            //catch si la conversation n'existe pas
            } catch (e) {}
        }
        return (
            <div>
                <div className={style.barre + " " + (!!this.state.open ? style.barreOuverte : style.barreFermee)} id={"barre"} onClick={this.handleClickMenu}>
                    <button className={`${style.visible} ${style.burgerButton} ${style.button}`}>
                        {this.state.open ? <FontAwesomeIcon icon={faXmark} /> : <FontAwesomeIcon icon={faBars} />}
                        {(!this.state.open && !window.location.pathname.includes("/chat") && message) ? <FontAwesomeIcon className={style.msg} icon={faMessage}/> : ""}
                    </button>
                    {/* Always appears under the burger menu button */}
                    <button disabled={true} className={style.button + " " + style.hidden}/>
                    {window.location.pathname === "/" ? "" : <Link to={"/"}><button className={`${style.button}`}><FontAwesomeIcon icon={faMap}/></button></Link>}
                    {window.location.pathname.includes( "/chat" ) ? "" :
                        <Link to={"/chat"}>
                            <button className={`${style.button}`}>
                                <FontAwesomeIcon icon={faMessage}/>
                                {(this.state.open && !window.location.pathname.includes("/chat") && message) ? <FontAwesomeIcon className={style.msg} icon={faMessage}/> : ""}
                            </button>
                        </Link>}
                    {window.location.pathname === "/classement" ? "" : <Link to={"/classement"}><button className={`${style.button}`}><FontAwesomeIcon icon={faRankingStar}/></button></Link>}
                    {window.location.pathname === "/help" ? "" : <Link to={"/help"}><button className={`${style.button}`}><FontAwesomeIcon icon={faCircleQuestion}/></button></Link>}
                    {window.location.pathname === "/settings" ? "" : <Link to={"/settings"}><button className={`${style.button}`}><FontAwesomeIcon icon={faGear}/></button></Link>}
                </div>
            </div>
        );
    }
}