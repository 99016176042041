import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import style from "./ItemTask.module.css";
import {Asset} from "../../Asset";
import {answers, apiUrl, completed, distance, equipe, off, on, partie} from "../../model";
import {faCheck, faClock, faLocationDot} from "@fortawesome/free-solid-svg-icons";

/**
 * Affiche une tâche dans la liste
 */
export class ItemTask extends React.Component {
    constructor(props) {
        super(props);
        this.distanceInterval = null;
        let trad;
        trad = this.props.task.traductions.find(traduction => traduction.language === localStorage.getItem("language"));
        if (!trad)
            trad = this.props.task.traductions.find(tr => tr.language === "en");
        if (!trad)
            trad = this.props.task.traductions[0];
        this.temp = false;
        this.state = {traduction: trad, nearEnough: this.isNearEnough()};
        this.isDisabled = this.isDisabled.bind(this);
        this.isNearEnough = this.isNearEnough.bind(this);
        this.updatePosition = this.updatePosition.bind(this);
        this.update = this.update.bind(this);
    }

    componentDidMount() {
        if (this.distanceInterval) clearInterval(this.distanceInterval);
        this.distanceInterval = setInterval(() => {
            this.setState({nearEnough: this.isNearEnough()})
        }, 5_000);
        on("position", this.updatePosition);
        on("received", this.update);
    }

    componentWillUnmount() {
        if (this.distanceInterval) clearInterval(this.stanceInterval);
        off("position", this.updatePosition);
        off("received", this.update);
    }

    update() {
        this.forceUpdate();
    }

    /**
     * Vérifie que l'équipe soit suffisamment près de la tâche pour y répondre
     * @return {boolean}
     * true : l'équipe est assez près
     * false : l'équipe est trop loin
     */
    isNearEnough() {
        if (!this.props.task.geolocalise) return true;
        return (distance(this.props.task) <= this.props.task.distanceMax);
    }

    /**
     * Vérifie la distance de l'équipe dès qu'elle bouge
     */
    updatePosition() {
        if (this.props.task.geolocalise)
            this.setState({nearEnough: this.isNearEnough()});
    }

    /**
     * Vérifie que tous les paramètres pour répondre à la tâche sont valides
     * @return {boolean}
     * true : l'équipe ne peut pas y répondre
     * false : l'équipe peut y répondre
     */
    isDisabled() {
        //Si l'équipe vient de répondre à la tâche et qu'elle n'est pas encore envoyée
        if (answers.find(a => a.task === this.props.task.id)) return true;
        const temp = partie.lieuxTemporaires.find(l => l.lieu.id === this.props.task.id);
        let disabled = !!temp;
        if (disabled) {
            this.temp = disabled;
            const now = Date.now();
            disabled = now <= new Date(temp.dateOuverture).getTime() + new Date().getTimezoneOffset() || now >= new Date(temp.dateFermeture).getTime() + new Date().getTimezoneOffset();
        }
        disabled = disabled || partie.etat !== "en cours" || !this.state.nearEnough || (this.props.task.nbPointsMin > equipe.points && this.props.task.nbPointsMin > 0);
        if (this.props.task.dependance) {
            if (this.props.task.lieuParent && !completed.has(this.props.task.lieuParent.id)) disabled = true;
            if (this.props.task.bonneReponseDependance && completed.get(this.props.task.lieuParent.id) === false) disabled = true;
        }
        if (partie.tacheDepart) {
            disabled ||= (!completed.has(partie.tacheDepart.id) && partie.tacheDepart.id !== this.props.task.id);
        }
        if (partie.etat === "terminee" && partie.tacheFin && this.props.task.id === partie.tacheFin.id && !completed.has(this.props.task.id)) disabled = false;
        return disabled;
    }

    render() {
        if (partie.etat === "terminee" && partie.tacheFin) {
            if (this.props.task.id !== partie.tacheFin.id) return undefined;
        } else {
            if (partie.tacheFin && this.props.task.id === partie.tacheFin.id) return undefined;
        }
        if (partie.tacheDepart && !completed.has(partie.tacheDepart.id) && partie.tacheDepart.id !== this.props.task.id)
            return undefined;

        // vrai si l'équipe a répondu correctement à la tâche, ou si elle n'a plus d'essais pour y répondre
        const reussi = !!(completed.get(this.props.task.id)?.juste || completed.get(this.props.task.id)?.nombre >= this.props.task.nbReponse);

        // min-height utile seulement si une image est affichée
        const _style = (this.props.task.liensFichiersPresentation?.length > 0 || reussi) ? {} : {maxHeight: "min-content !important", minHeight: "unset"};
        return (
            <div onClick={() => this.props.setTask(this.props.task)} style={_style}
                 className={`${style.item} ${this.isDisabled() ? style.disabled : ""}`}>
                <div style={_style} className={`${style.asset} ${this.props.task.liensFichiersPresentation?.length > 0 ? style.shadow : ""}`}>{reussi ? <Asset blockClick={true} lien={"logos/verified.jpeg"}/> : this.props.task.liensFichiersPresentation?.length > 0 && <Asset blockClick={true} lien={this.props.task.liensFichiersPresentation[0]}/>}</div>
                <div className={style.item_container} style={_style}>
                    <h4 className={`${style.h4} ${reussi ? style.barre : ""}`}>
                        {this.state.traduction.title}
                    </h4>
                    {/* Barre des icônes */}
                    <div className={style.icons}>
                        <span className={style.nbPoints}>{this.props.task.nbPointsGagnes}</span>
                        {reussi && <FontAwesomeIcon style={{color: "green", fontSize: "1.5rem"}} icon={faCheck}/>}
                        {<img style={{minWidth: "1rem", minHeight: "1rem"}} src={`${apiUrl}/${this.props.task.icon}`} alt={""}/>}
                        {this.props.task.categorie?.icon && <img style={{minWidth: "1rem", minHeight: "1rem"}} src={`${apiUrl}/${this.props.task.categorie.icon}`} alt={''} />}
                        {this.props.task.geolocalise && <FontAwesomeIcon style={{color: "red"}} icon={faLocationDot}/>}
                        {this.temp && <FontAwesomeIcon icon={faClock}/>}
                    </div>
                    <p style={_style} className={style.description}>{this.state.traduction.description}</p>
                </div>
            </div>
        );
    }
}