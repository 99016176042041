import React from "react";
import {partie} from "../model";
import s from "./Timer.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faClock} from "@fortawesome/free-solid-svg-icons";

export class Timer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {time: Math.ceil((new Date(partie.dateFin).getTime() - new Date().getTimezoneOffset() - Date.now() )/1000)};
        this.inter = null;
    }

    componentDidMount() {
        // Mets à jour le timer toutes les 10 sec s'il reste + d'une heure, toutes les secondes sinon
        this.inter = setInterval(() => this.update(), this.state.time > 3600 * 1000 ? 10_000 : 1000);
    }

    componentWillUnmount() {
        clearInterval(this.inter);
    }

    update() {
        if (partie.etat === "terminee") {
            clearInterval(this.inter);
            this.setState({time: 0});
            return;
        }
        this.setState({time: Math.ceil((new Date(partie.dateFin).getTime() - new Date().getTimezoneOffset() - Date.now() )/1000)});
        if (this.state.time < 3600 * 1000) {
            clearInterval(this.inter);
            this.inter = setInterval(() => this.update(), 1000);
        }
    }

    /**
     * Affiche le temps restant sous la forme d'une string.
     * @return {string|null}
     */
    getTimeString() {
        if (isNaN(this.state.time)) return null;
        if (partie.etat === "terminee") {
            return null;
        }
        let s = this.state.time % 60;
        let m = Math.floor((this.state.time % 3_600) / 60);
        let h = Math.floor((this.state.time % 3_600_000) / 3600);
        if (h > 0) {
            return `${`${h}`.length > 1 ? h : `0${h}`}h ${`${m}`.length > 1 ? m : `0${m}`}m`;
        }
        return `${`${m}`.length > 1 ? m : `0${m}`}m ${`${s}`.length > 1 ? s : `0${s}`}s`;
    }

    render() {
        return (
            <span className={`${s.p} ${partie.etat === "terminee" ? s.red : s.blue}`}>
                <FontAwesomeIcon icon={faClock} /> {this.getTimeString() ?? (localStorage.getItem("language") === "fr" ? "Temps écoulé" : "Time's up")}
            </span>
        );
    }
}