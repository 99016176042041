import style from "./TeamList.module.css";
import {ItemTeam} from "./ItemTeam";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

export function TeamList({classement}) {
    return (
        <div className={style.full}>
            <table className={style.list}>
                <caption className={style.caption}>{localStorage.getItem("language") === "fr" ? "CLASSEMENT" : "HIGHSCORE"}</caption>
                <thead><tr>
                    <td className={style.icon}><FontAwesomeIcon icon={faUser}/></td><td className={style.team}>{localStorage.getItem("language") === 'fr' ? "Équipe" : "Team"}</td><td className={style.points}>Points</td>
                </tr></thead>
                <tbody className={style.tbody}>
                {classement.map(c => <ItemTeam key={c.id} team={c}/>)}
                </tbody>
            </table>
        </div>
    )
}