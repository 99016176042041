import {io} from "socket.io-client";
import Swal from "sweetalert2";
import axios from "axios";
import {Jingle} from "./Jingle";

//export const apiUrl = "https://192.168.0.14:8080";
export const apiUrl = "https://api.teambuildings.live";
export const socket = io(apiUrl);
export const equipe = {code: ""};
export const partie = {tasks: []};
export const position = {lat: 0, lon: 0, precision: 0, pin: null};
export const team_locations = new Map();
let sendInterval = null;
let formInterval = null;
const events = [];
export const answers = [];
const sent = [];
const forms = [];
export const conversations = [];
export const completed = new Map();
export const date_connexion = Date.now();
let task;
let ask_language = true;

/**
 * Answers a task
 * @param ans {{task: Object, answer: string | number, date?: Date}}
 */
export function answer(ans) {
    if (partie.etat === "terminee" && !ans.date && !(partie.tacheFin && ans.task.id === partie.tacheFin.id)) {
        Swal.fire({
            icon: "error",
            title: localStorage.getItem("language") === "fr" ? "La partie est terminée" : "The game is over."
        });
        return false;
    }
    if (ans.task.geolocalise) {
        if (distance(ans.task) > ans.task.distanceMax) {
            Jingle(false);
            Swal.fire({
                icon: "error",
                title: localStorage.getItem("language") === "fr" ? "Vous êtes trop loin de cette tâche" : "You are too far away from this task.",
            });
            return false;
        }
    }
    answers.push({date: ans.date ?? Date.now(), answer: ans.answer, task: ans.task.id, lat: position.lat, lon: position.lon});
    send();
    emit('answer');
    return true;
}

/**
 * Answers a task with a picture or a video
 * @param ans {{task: Object, answer: FormData}}
 */
export function answerPhotoVideo(ans) {
    if (partie.etat === "terminee" && !(partie.tacheFin && partie.tacheFin.id === ans.task.id)) {
        Swal.fire({
            icon: "error",
            title: localStorage.getItem("language") === "fr" ? "La partie est terminée" : "The game is over."
        });
        return false;
    }
    forms.push({date: Date.now(), task: ans.task.id, form: ans.answer});
    sendPhotoVideo();
    return true;
}

function send() {
    if (socket.connected) {
        socket.emit("answer", {answers, game: partie.id, team: equipe.code, lat: position.lat, lon: position.lon});
        sent.push(...answers.splice(0));
        clearInterval(sendInterval);
    } else {
        clearInterval(sendInterval);
        sendInterval = setInterval(send, 5_000);
    }
}

function sendPhotoVideo() {
    if (forms.length === 0) return;
    const f = forms[0];
    axios.post(`${apiUrl}/Partie/${partie.id}/answer/${equipe.code}/${f.task}`, f.form, {
        headers: {
            "Content-type": "multipart/form-data"
        }
    }).then(r => {
        forms.splice(0, 1);
        answer({task: {id: f.task}, answer: r.data, date: f.date});
        //prevents recursion
        if (forms.length > 0) setTimeout(sendPhotoVideo, 0);
    }).catch(e => {
        console.error(e);
        clearInterval(formInterval);
        formInterval = setInterval(sendPhotoVideo, 5_000);
    });
}

socket.emit("date", date_connexion);

socket.on("received", received => {
    for (const {id, juste, nombre} of received) {
        const index = sent.findIndex(p => p.task === id);
        if (index >= 0) sent.splice(index, 1);
        console.log({id, juste, nombre});
        completed.set(id, {juste, nombre});
    }
    socket.emit("nbPoints", equipe.id);
    emit("received");
});

socket.on("nbPoints", p => {
    equipe.points = p;
    emit("points");
});

socket.on("conversations", conv => {
    for (const prop in conversations) delete conversations[prop];
    for (const prop in conv) conversations[prop] = conv[prop];
    emit("conversations");
});

socket.on("message", msg => {
    if (!equipe.id) {
        if (!conversations || conversations.length === 0) return socket.emit("conv_anon", partie.id, date_connexion);
        conversations[0].messages.push(msg);
        emit("message");
        return;
    }
    const autre = msg.sender === equipe.id ? msg.to : msg.sender;
    let conv = conversations[isNaN(+autre) ? autre : `_${autre}`];
    if (!conv) {
        if (!equipe.id) {
            const seen = {};
            seen[`${date_connexion}`] = 0;
            conv = {seen, messages: []};
            conversations.push(conv);
        } else return socket.emit("conversations", partie.id, equipe.id);
    }
    conv.messages.push(msg);
    if (!window.location.pathname.includes(`/chat/${msg.sender}`) && msg.sender !== equipe.id) {
        const title = msg.sender === "admin" ? "Admin" : partie.equipes?.find(e => e.id === msg.sender)?.name;
        Swal.fire({
            toast: true,
            position: 'top-end',
            timer: 3000,
            timerProgressBar: true,
            text: msg.message.substring(0, 100),
            showConfirmButton: false,
            icon: "info",
            didOpen(popup) {
                popup.onclick = ()=>{emit("redirect", `/chat/${msg.sender}`); popup.remove();}
            },
            titleText: title ? title : localStorage.getItem("language") === 'fr' ? "Nouveau message" : "New message"
        });
    }
    emit("message");
});

socket.on("message_all", msg => {
    const conv = conversations["everyone"];
    conv.messages.push(msg);
    if (!window.location.pathname.includes("/chat/everyone") && msg.sender !== equipe.id) {
        const title = msg.sender === "admin" ? "Admin" : partie.equipes?.find(e => e.id === msg.sender)?.name;
        Swal.fire({
            toast: true,
            position: 'top-end',
            timer: 3000,
            timerProgressBar: true,
            text: msg.message.substring(0, 100),
            showConfirmButton: false,
            icon: "info",
            didOpen(popup) {
                popup.onclick = () => {
                    emit("redirect", "/chat/everyone");
                    popup.remove();
                }
            },
            titleText: title ? title : localStorage.getItem("language") === 'fr' ? "Nouveau message" : "New message"
        });
    }
    emit("message");
});

export function setEquipe(eq) {
    for (const prop in eq)
        equipe[prop] = eq[prop];
    /*for (const r of eq.reponses) {
        completed.set(r.task.id, r.juste);
    }*/
    socket.emit("nbPoints", equipe.id);
}

/**
 * Donne la distance entre la tâche donnée en paramètre et notre position
 * @param task {{lat: number, lon: number}}
 * La tâche
 * @return {number}
 * la distance
 */
export function distance(task) {
    // cf. https://stackoverflow.com/a/11172685/15051033
    const R = 6378.137; // Radius of earth in KM
    const dLat = task.latitude * Math.PI / 180 - position.lat * Math.PI / 180;
    const dLon = task.longitude * Math.PI / 180 - position.lon * Math.PI / 180;
    const truc = Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(task.latitude * Math.PI / 180) * Math.cos(position.lat * Math.PI / 180) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(truc), Math.sqrt(1 - truc));
    return Math.max(0, (R * c * 1000) - position.precision);
}

/**
 * Enregistre un callback pour un événement donné
 * @param event {string}
 * @param cb {Function}
 */
export function on(event, cb) {
    events[event] ??= [];
    events[event].push(cb);
}

/**
 * Retire un callback pour un événement donné
 * @param event {string}
 * @param cb {Function}
 */
export function off(event, cb) {
    events[event] ??= [];
    const index = events[event].indexOf(cb);
    if (index === -1) return;
    events[event].splice(index, 1);
}

/**
 * Exécute tous les callbacks pour l'événement, avec les paramètres supplémentaires fournis
 * @param event {string}
 * @param data {any}
 */
export function emit(event, ...data) {
    if (!events[event]) return;
    for (const cb of events[event]) {
        try {
            cb(...data);
        } catch (e) {
            console.error(e);
        }
    }
}

/**
 * Remplace tous les caractères spéciaux d'une string l'afficher sans en exécuter du code
 * @param string
 * @return {string}
 */
export function escapeHtml(string) {
    return string
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
}

/**
 * Utilisé pour flasher un QR code de tâche.
 * @param t {Object}
 */
export function setTask(t) {task = t;}
export function getTask() {return task;}

/**
 * Utiliser pour savoir s'il faut demander à l'utilisateur s'il veut changer de langage ou non (seulement la première fois qu'il se connecte)
 * @param t {boolean}
 */
export function set_ask_language(t) {ask_language = t;}
export function get_ask_language() {return ask_language;}