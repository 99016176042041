import React from "react";
import s from './Chat.module.css';
import {apiUrl, conversations, equipe, off, on, partie, socket} from "../model";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMessage} from "@fortawesome/free-solid-svg-icons";

/**
 * Menu de sélection de conversation
 */
export class Chat extends React.Component {
    constructor(...props) {
        super(...props);
        this.refresh = this.refresh.bind(this);
    }
    refresh() {
        this.forceUpdate();
    }

    componentDidMount() {
        if (partie.id && equipe.id)
            socket.emit("conversations", partie.id, equipe.id);
        on("message", this.refresh);
        on("equipes", this.refresh);
        on('refresh', this.refresh);
        on('conversations', this.refresh);
    }

    componentWillUnmount() {
        off("message", this.refresh);
        off("equipes", this.refresh);
        off("refresh", this.refresh);
        off("conversations", this.refresh);
    }
    render() {
        const eq = partie.equipes?.find(eq => eq.id === equipe.id) ?? equipe;
        return (
            <div>
                <h1 className={s.h1}>{localStorage.getItem("language") === "fr" ? "TCHAT" : "CHAT"}</h1>
                <div className={s.div}>
                    {(eq && eq.parleAuxAutres) && (
                        <Link className={s.a} key={-1} to={"/chat/everyone"}>
                            <div className={s.item}>
                                <img className={s.img + " " + s.hidden} src={""} alt={""}/>
                                <span className={s.span}>
                                    {localStorage.getItem("language") === "fr" ? "Tout le monde" : "Everyone"}
                                    {conversations["everyone"]?.seen[`_${equipe.id}`] < conversations["everyone"]?.messages?.length &&
                                        <span className={s.numero}><span>{conversations["everyone"]?.messages?.length - conversations["everyone"]?.seen[`_${equipe.id}`]}</span><FontAwesomeIcon className={s.msg} icon={faMessage} /></span>
                                    }
                                </span>
                            </div>
                        </Link>
                    )}
                    <Link className={s.a} key={0} to={"/chat/admin"}>
                        <div className={s.item}>
                            <img className={s.img + " " + s.hidden} src={""} alt={""}/>
                            <span className={s.span}>
                                Admin
                                {
                                    conversations["admin"]?.seen[`_${equipe.id}`] < conversations["admin"]?.messages?.length &&
                                        <span className={s.numero}><span>{conversations["admin"]?.messages?.length - conversations["admin"]?.seen[`_${equipe.id}`]}</span><FontAwesomeIcon className={s.msg} icon={faMessage}/></span>
                                }
                            </span>
                        </div>
                    </Link>
                    {partie.equipes?.map(
                        e => {
                            if (e.id === equipe.id) return null;
                            if ((eq?.parleAuxAutres) === false) return null;
                            if (e.parleAuxAutres === false) return null;
                            const conversation = conversations[`_${e.id}`];
                            let unseen = conversation ? conversation.messages.length - conversation.seen[`_${equipe.id}`] : 0;
                            return (<Link className={s.a} key={e.id} to={"/chat/" + e.id}>
                                <div className={s.item}>
                                    <img src={`${apiUrl}/${e.icon}`} alt={""} className={s.img}/>
                                    <span className={s.span}>
                                        {e.name}
                                        {unseen > 0 &&
                                        <span className={s.numero}><span>{unseen}</span><FontAwesomeIcon className={s.msg} icon={faMessage}/></span>}
                                    </span>
                                </div>
                            </Link>);
                        }
                    )}
                </div>
            </div>
        );
    }
}