import React from "react";
import * as qr from "qrcode";
import s from "./Settings.module.css";
import {apiUrl, equipe, off, on, partie, socket, answers} from "../model";

/**
 * Affiche la page des paramètres
 */
export class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {qrCodeUrl: "", lang: localStorage.getItem("language")};
        this.handleChangeLanguage = this.handleChangeLanguage.bind(this);
        this.createOptionsLanguage = this.createOptionsLanguage.bind(this);
        this.toggleFullScreen = this.toggleFullScreen.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    componentDidMount() {
        //Affiche le qr code de connexion de l'équipe
        qr.toDataURL(`${window.location.protocol}//${window.location.hostname}${window.location.port.length > 0 ? `:${window.location.port}` : ""}/?code=${localStorage.getItem("code")}&idPartie=${localStorage.getItem("idPartie")}`, (err, url) => {
            if (url) {
                this.setState({qrCodeUrl: url});
            }
            if (err) console.error(err);
        });
        on("refresh", this.refresh);
        on("answer", this.refresh);
        on("received", this.refresh);
        on("reconnect", this.refresh);
        on("disconnect", this.refresh);
    }

    componentWillUnmount() {
        off("refresh", this.refresh);
        off("answer", this.refresh);
        off("received", this.refresh);
        off("reconnect", this.refresh);
        off("disconnect", this.refresh);
    }

    refresh() {
        this.forceUpdate();
    }

    /**
     * Change la langue
     * @param e {Event}
     */
    handleChangeLanguage(e) {
        localStorage.setItem("language", e.target.value);
        this.setState({lang: localStorage.getItem('language')});
    }

    /**
     * Crée toutes les options de langue en fonction des traductions des tâches (minimum FR, EN, plus toutes les traductions des tâches)
     */
    createOptionsLanguage() {
        const languages = [];
        for (const t of partie.tasks) {
            for (const trad of t.task.traductions) {
                if (!languages.includes(trad.language)) languages.push(trad.language);
            }
        }
        if (!languages.includes("en")) languages.push("en");
        if (!languages.includes("fr")) languages.push("fr");
        return languages.map((t, index) => <option key={index} value={t}>{t}</option> );
    }

    /**
     * Passe l'appli en grand écran (ou en sort)
     */
    toggleFullScreen() {
        if (!window.fullScreenApi.supportsFullScreen) return;
        window.fullScreenApi.isFullScreen()
            ? window.fullScreenApi.cancelFullScreen(document.documentElement)
            : window.fullScreenApi.requestFullScreen(document.documentElement);
    }

    render() {
        return (
            <div className={s.div}>
                <img className={s.img} src={`${apiUrl}/${equipe.icon ?? "icons/default.svg"}`} alt={""}/>
                <h2>{equipe.name}</h2>
                {this.state.qrCodeUrl ?
                    (<div className={s.qr}>
                        <p>{this.state.lang === "fr" ? "QR code de connexion" : "Connection QR code"}</p>
                        <img className={s.img_qr} src={this.state.qrCodeUrl}
                            alt={`${window.location.protocol}//${window.location.hostname}${window.location.port.length > 0 ? `:${window.location.port}` : ""}/?code=${localStorage.getItem("code")}&idPartie=${localStorage.getItem("idPartie")}`}/>
                    </div>)
                    : ""}
                <div className={s.params}>
                    <select value={this.state.lang} className={s.select + " button"} onChange={this.handleChangeLanguage}>{this.createOptionsLanguage()}</select>
                    {window.fullScreenApi.supportsFullScreen && <button onClick={this.toggleFullScreen} className={"button " + s.fullScreen}>{this.state.lang === "fr" ? "Plein écran" : "Toggle fullscreen"}</button>}
                </div>
                <div style={{padding: "1rem 0", textAlign: "center"}}>
                    <span>{this.state.lang === 'fr' ? ("Vous êtes " + (socket.connected ? "connectés" : "déconnectés")) : "You are " + (socket.connected ? "connected" : "disconnected")}</span>
                    {answers.length > 0 && (<><br/><span>{this.state.lang === "fr" ? `Vous avez ${answers.length} tâche(s) en attente d'envoi, ne rafraichissez pas l'application` : `You have ${answers.length} task(s) waiting for upload, don't refresh the app`}</span></>)}
                </div>
            </div>
        );
    }
}