import React from "react";
import s from './Inscription.module.css';
import axios from "axios";
import {apiUrl, date_connexion} from "../model";
import Swal from "sweetalert2";

/**
 * Formulaire de login / création d'équipe
 */
export class Inscription extends React.Component {
    constructor(props) {
        super(props);
        this.state = {name: "", key: "", help: false};
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleKeyChange = this.handleKeyChange.bind(this);
        this.send = this.send.bind(this);
        this.help = this.help.bind(this);
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        if (urlParams.get("idPartie"))
            localStorage.setItem("idPartie", urlParams.get("idPartie"));
        if (urlParams.get("token"))
            localStorage.setItem("token", urlParams.get("token"));
    }

    handleNameChange(e) {
        this.setState({name: e.target.value});
    }

    handleKeyChange(e) {
        this.setState({key: e.target.value});
    }

    /**
     * Envoie la requête pour se connecter ou créer une équipe
     * @return {Promise<void>}
     */
    async send() {
        try {
            const res = (await axios({
                method: "put",
                url: `${apiUrl}/game/equipe`,
                data: {
                    name: this.state.name.substring(0, 50),
                    key: this.state.key.substring(0, 50),
                    idPartie: +localStorage.getItem("idPartie"),
                    token: localStorage.getItem("token"),
                    date: date_connexion
                }
            })).data;
            localStorage.setItem("code", res.code);
            localStorage.setItem("idPartie", res.partie ?? localStorage.getItem("idPartie"));
            window.location.replace("/");
        } catch (e) {
            await Swal.fire({
                icon: "error",
                title: e.response.data ?? e
            });
        }
    }

    /**
     * Affiche le texte d'aide
     * @return {JSX.Element}
     */
    help() {
        return localStorage.getItem("language") === "fr" ?
            (<div className={s.help}>Rentrez le nom de votre équipe (ou un nouveau nom) avec une clé de connexion. Vous devrez vous souvenir de cette clé pour vous reconnecter.</div>)
            : (<div className={s.help}>Enter your team's name (or a new name) and the connection key. You'll have to remember this key to reconnect if you need to.</div>)
    }

    render() {
        return (
            <div className={s.full}>
                <div className={s.form}>
                    {this.state.help ? this.help() : ""}
                    <button className={s.button} onClick={() => {this.setState(state => ({help: !state.help}))}}>{localStorage.getItem("language") === "fr" ? "Afficher / cacher l'aide" : "Show / hide help"}</button>
                    <label>
                        {localStorage.getItem("language") === "fr" ? "Nom d'équipe" : "Team name"}
                    </label>
                    <input className={s.input} type={"text"} onInput={this.handleNameChange} maxLength={50}/>
                    <label>
                        {localStorage.getItem("language") === "fr" ? "Clé de connexion" : "Connection key"}
                    </label>
                    <input className={s.input} type={"text"} maxLength={50} onInput={this.handleKeyChange}/>
                    <button className={s.button} onClick={this.send}>{localStorage.getItem("language") === "fr" ? "Se connecter" : "Log in"}</button>
                </div>
            </div>
        );
    }
}