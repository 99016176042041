import {Outlet} from "react-router-dom";
import {NavBar} from "../NavBar/";
import {apiUrl, off, on} from "../model";
import {History} from "../History";
import {Component} from "react";
import ReactConfetti from "react-confetti";

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {lien: null};
        this.onLien = this.onLien.bind(this);
        this.redirect = this.redirect.bind(this);
        this.rm_redirect = this.rm_redirect.bind(this);
        this.confettis = this.confettis.bind(this);
    }

    componentDidMount() {
        on("redirected", this.rm_redirect);
        on("redirect", this.redirect)
        on("photo", this.onLien);
        on("confettis", this.confettis);
    }

    componentWillUnmount() {
        off('photo', this.onLien);
        off("redirected", this.rm_redirect);
        off("redirect", this.redirect);
        off("confettis", this.confettis);
    }

    rm_redirect() {
        this.setState({redirect: null});
    }

    /**
     * Change les confettis
     * @param c {boolean}
     * true : affiche des confettis
     * false : retire les confettis
     */
    confettis(c) {
        this.setState({confettis: c});
    }

    /**
     * Redirige l'utilisateur vers l'url demandé
     * @param l {string}
     * l'Url
     */
    redirect(l) {
        this.setState({redirect: l});
    }

    /**
     * Affiche une image en grand
     * @param l {string}
     * L'url de l'image
     */
    onLien(l) {
        this.setState({lien: l});
    }

    render() {
        /**
         * Redirige l'utilisateur vers `this.state.redirect`
         */
        if (this.state.redirect) {
            return (<History lien={this.state.redirect}/>);
        }
        return (
            <>
                <NavBar/>
                <Outlet/>
                {/* Affiche une image en grand */}
                {this.state.lien &&
                (<div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100vw",
                        height: "100vh",
                        backgroundColor: "#000c",
                        zIndex: 9999,
                    }}
                    onClick={() => {
                        this.setState({lien: null})
                    }}>
                    <img
                        src={`${apiUrl}/${this.state.lien}`}
                        alt={""}
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            maxWidth: "100vw",
                            maxHeight: "100vh",
                        }}
                    />
                </div>)}
                {/* Affiche des confettis qui tombent */}
                {this.state.confettis === true && <ReactConfetti recycle={false} onConfettiComplete={() => {this.setState({confettis: false})}} numberOfPieces={1000} style={{position: "absolute", zIndex: 999999, top: 0, left: 0}}/>}
            </>
        );
    }
}