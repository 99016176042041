import {createRef, Component} from "react";
import s from "./Conversation.module.css";
import {conversations, date_connexion, emit, equipe, off, on, partie, socket} from "../../model";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleArrowLeft, faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import {MessageList} from "./MessageList";
import {Link} from "react-router-dom";

/**
 * Conversation entre une équipe et une autre équipe / l'admin / toute la partie
 */
export class Conversation extends Component {
    constructor(props) {
        super(props);
        if (!equipe.id && !window.location.pathname.includes("/chat/admin") && !localStorage.getItem("code")) return window.location.href = "/chat/admin";
        this.id = window.location.href.split("/")[window.location.href.split("/").length - 1];
        this.state = {
            conversation: conversations[isNaN(+this.id) ? this.id : `_${this.id}`],
            text: ""
        };
        if (!this.state.conversation && partie.id && equipe.id)
                socket.emit("conversations", partie.id, equipe.id);

        this.messages = createRef();
        this.input = createRef();
        this.refresh = this.refresh.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.send = this.send.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.onReceive = this.onReceive.bind(this);
    }

    componentDidMount() {
        on("conversations", this.refresh);
        on("refresh", this.refresh);
        on("message", this.onReceive);
        window.addEventListener("keydown", this.handleKeyDown);
        this.messages.current?.scrollTo(0, this.messages.current?.scrollHeight);
    }

    componentWillUnmount() {
        off("conversation", this.refresh);
        off("refresh", this.refresh);
        off("message", this.onReceive);
        if (partie.id && equipe.id && this.id &&  this.state.conversation?.messages?.length)
            socket.emit("seen", {game: partie.id, from: equipe.id, to: this.id, nombre: this.state.conversation.messages.length});
        window.removeEventListener("keydown", this.handleKeyDown);
    }

    componentDidUpdate() {
        this.messages.current?.scrollTo(0, this.messages.current?.scrollHeight);
    }

    /**
     * Met à jour la conversation
     */
    refresh() {
        if (equipe.id)
            this.setState({conversation: conversations[isNaN(+this.id) ? this.id : `_${this.id}`]});
        else
            this.setState({conversation: conversations[0]});
    }

    handleKeyDown(e)  {
        if (e.key === "Enter" && !e.ctrlKey) this.send();
        if (e.key !== "Tab" && e.key !== "Shift") {
            this.input.current?.focus();
        }
    }

    /**
     * Réception d'un message
     */
    onReceive() {
        if (!this.state.conversation) return;
        this.setState(s => {
            s.conversation.seen[equipe.id ? `_${equipe.id}` : `${date_connexion}`] = s.conversation.messages.length;
        });
        this.forceUpdate(() => {this.messages.current?.scrollTo(0, this.messages.current?.scrollHeight)});
    }

    /**
     * Envoi d'un message
     */
    send() {
        if (this.state.text.trim().length === 0) return;
        const msg = {
            game: partie.id,
            sender: equipe.id ?? date_connexion,
            to: this.id,
            message: this.state.text,
            date: Date.now() + new Date().getTimezoneOffset() * 60_000
        };
        socket.emit(equipe.id ? "message" : "message_anon", msg);

        this.setState({text: ""});
    }

    handleInput(e) {
        this.setState({text: e.target.value});
    }

    render() {
        const eq = partie?.equipes?.find(e => `${e.id}` === `${this.id}`) ?? equipe;
        if (eq && !eq.parleAuxAutres && !window.location.pathname.includes("/chat/admin")) return emit("redirect", "/chat");
        let name = isNaN(+this.id) ? this.id : partie.equipes.find(e => `${e.id}` === this.id).name;
        if (this.id === "admin") name = "Admin";
        if (this.id === "everyone") name = localStorage.getItem("language") === "fr" ? "Tout le monde" : "Everyone";
        return (
            <div className={s.bg}>
                <div className={s.full}>
                    <Link to={localStorage.getItem("code") ? "/chat" : "/partie"}><button className={s.back}><FontAwesomeIcon icon={faCircleArrowLeft}/></button></Link>
                    <h1 className={s.h1}>{name}</h1>
                    <div ref={this.messages} className={s.messages}><MessageList messages={this.state.conversation?.messages}/></div>
                    <div className={s.barre}>
                        <input className={s.input} type={"text"} onInput={this.handleInput} value={this.state.text} placeholder={(localStorage.getItem("language") === "fr" ? "Envoyer un message à " : "Send a message to ") + (name)} ref={this.input}/>
                        <button onClick={this.send} className={s.btn}><FontAwesomeIcon icon={faPaperPlane}/></button>
                    </div>
                </div>
            </div>
        );
    }
}