import s from "./JokerList.module.css";
import {equipe, socket} from "../model";
import {Component} from "react";
import {Joker} from "../Joker";
import Swal from "sweetalert2";

/**
 * Affiche tous les jokers d'une traduction pour les acheter (ou les revoir)
 */
export class JokerList extends Component {
    constructor(props) {
        super(props);
        this.state = {joker: null};
        this.handleJokerClick = this.handleJokerClick.bind(this);
    }

    /**
     * Achète le joker s'il n'a pas déjà été acheté,
     * Sinon, l'affiche
     * @param joker {Object}
     */
    handleJokerClick(joker) {
        if (equipe.jokers.find(j => j.id === joker.id)) return this.setState({joker});
        const fr = localStorage.getItem("language") === "fr";
        Swal.fire({
            title: fr ? `Acheter ce joker pour ${joker.price} points ?` : `Buy this joker for ${joker.price} points ?`,
            text: fr ? `Vous avez actuellement ${equipe.points} points` : `You currently have ${equipe.points} points`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: fr ? "Oui" : "Yes",
            cancelButtonText: fr ? "Non" : "No"
        }).then((result) => {
            if (result.isConfirmed) {
                socket.emit("joker", equipe.id, joker.id);
                equipe.jokers.push(joker);
                this.setState({joker});
            }
        });
    }

    render() {
        return this.state.joker
            ? <Joker close={() => this.setState({joker: null})} joker={this.state.joker}/>
            : (
                <table className={s.div}>
                    <caption>JOKERS</caption>
                    <tbody>
                    {this.props.jokers.map(j =>
                        <tr key={j.id} onClick={() => {this.handleJokerClick(j)}}>
                            <td className={s.joker}>
                                Joker n°{this.props.jokers.indexOf(j) + 1} -{" "}
                                {equipe.jokers?.find(jok => jok.id === j.id) ?
                                    (localStorage.getItem("language") === "fr" ? "Revoir le joker" : "See the joker again") :
                                    `${localStorage.getItem("language") === "fr" ? "Prix" : "Price"} : ${j.price}`
                                }
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            );
    }
}