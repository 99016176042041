import React from "react";
import {equipe, off, on, partie, socket} from "../model";
import {TeamList} from "./TeamList";
import style from './Classement.module.css';

export class Classement extends React.Component {
    constructor(props) {
        super(props);
        this.state = { classement: [] };
        this.order = this.order.bind(this);
        this.onPoints = this.onPoints.bind(this);
        this.update = this.update.bind(this);
    }

    /**
     * Met à jour les points d'une équipe
     * @param equipe {number}
     * @param points {number}
     */
    onPoints({equipe, points})  {
        const tmp = [...this.state.classement];
        const index = tmp.findIndex(t => t.id === equipe);
        tmp[index].nbPoints += points;
        this.order(tmp);
    }

    componentDidMount() {
        on("equipes", this.update);
        socket.emit("classement", partie.id ? partie.id : +localStorage.getItem("idPartie"));
        socket.on("classement", this.order);
        socket.on("points", this.onPoints);
    }

    componentWillUnmount() {
        off("equipes", this.update);
        socket.off("points", this.onPoints);
        socket.off("classement", this.order);
    }

    /**
     * Met à jour le classement
     */
    update() {
        socket.emit("classement", partie.id ? partie.id : +localStorage.getItem("idPartie"));
    }

    /**
     * Mets les équipes dans le bon ordre
     * @param classement
     */
    order(classement) {
        const team = classement.find(c => c.code === equipe.code);
        if (team) equipe.points = team.nbPoints;
        const c = [];
        while (classement.length > 0) {
            let max = -Infinity;
            let index = -1;
            for (let i = 0; i < classement.length; i++) {
                if (classement[i].nbPoints >= max) {
                    max = classement[i].nbPoints;
                    index = i;
                }
            }
            if (classement[index].visibleClassement)
                c.push(classement.splice(index, 1)[0]);
            else classement.splice(index, 1);
        }
        this.setState({classement: c});
    }

    render() {
        return (
            <div className={style.div}>
                <TeamList classement={this.state.classement} />
            </div>
        );
    }
}