import s from "./Help.module.css";

/**
 * Affiche la page d'aide
 * @return {JSX.Element}
 */
export function Help() {
    if (localStorage.getItem("language") === "fr") {
        return (
            <div className={s.div}>
                <h2 className={s.titre}>AIDE</h2>
                <div>
                    <p>Bienvenue</p>
                </div>
            </div>
        );
    }
    return (
        <div className={s.div}>
            <h2 className={s.titre}>HELP</h2>
            <div>
                <p>Welcome</p>
            </div>
        </div>
    );
}