import {Carousel} from "../../Carousel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import s from "./Popup.module.css";
import {faPersonWalking, faXmark} from "@fortawesome/free-solid-svg-icons";
import {apiUrl} from "../../model";

export function Popup({task, guide, setTask, removePopup}) {
    let trad = task.traductions.find(t => t.language === localStorage.getItem("language"));
    if (!trad) trad = task.traductions.find(t => t.language === "en");
    if (!trad) trad = task.traductions[0];
    return (
        <>
            <button className={s.close} onClick={removePopup}><FontAwesomeIcon icon={ faXmark } /></button>
            <h3 className={s.h3}><img src={`${apiUrl}/${task.icon}`} alt={""} style={{width: "1rem", height: "1rem"}}/> {trad.title} - {task.nbPointsGagnes}pts</h3>
            <Carousel _style={{height: "20vh"}} liens={task.liensFichiersPresentation}/>
            <p className={s.p}>{trad.description}</p>
            <button onClick={guide} className={s.itineraire}><FontAwesomeIcon icon={ faPersonWalking }/></button>
            <button className={'button ' + s.btn} onClick={() => setTask(task)}>{localStorage.getItem("language") === "fr" ? "Ouvrir" : "Open"}</button>
        </>
    );
}