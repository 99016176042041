import React from "react";
import style from "./TaskList.module.css";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleDown, faAngleUp} from "@fortawesome/free-solid-svg-icons";
import {ItemTask} from "./ItemTask";
import {socket, partie, distance, completed, on, off} from "../model";
import Masonry from 'react-masonry-css'
import Swal from "sweetalert2";

/**
 * Affiche toutes les tâches sous forme de liste
 */
export class TaskList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {opened: false, nbCol: Math.max(1, Math.floor(window.innerWidth / 350)), sortOption: "-1"};
        this.handleDragClick = this.handleDragClick.bind(this);
        this.onResize = this.onResize.bind(this);
        this.update = this.update.bind(this);
        this.sortTasks = this.sortTasks.bind(this);
        this.close = this.close.bind(this);
    }

    componentDidMount() {
        on("get_tasks", this.update);
        socket.on("received", this.update);
        socket.on("points", this.update);
        socket.on("reinit", this.update);
        on("close_list", this.close);
        if (!localStorage.getItem("idPartie")) {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            if (urlParams.get("idPartie"))
                localStorage.setItem("idPartie", urlParams.get("idPartie"));
            else {
                Swal.fire({
                    icon: "error",
                    title: localStorage.getItem("language") === "fr" ?
                        "Impossible de trouver l'id de la partie, veuillez flasher à nouveau le qr code" :
                        "Impossible to find the game's id, please flash the QR code again"
                });
            }
        }
        window.addEventListener("resize", this.onResize);
    }

    update() {
        this.forceUpdate();
    }

    close() {
        this.setState({opened: false});
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onResize);
        socket.off("received", this.update);
        socket.off("points", this.update);
        socket.off("reinit", this.update);
        off("get_tasks", this.update);
        off("close_list", this.close);
    }

    /**
     * affiche à nouveau les tâches avec le bon nombre par ligne
     */
    onResize() {
        this.setState({nbCol: Math.max(1, Math.floor(window.innerWidth / 350))});
    }

    /**
     * Ouvre ou ferme la liste au click sur le volet
     * @param e {Event}
     */
    handleDragClick(e) {
        e.preventDefault();
        this.setState(state => ({opened: !state.opened}));
    }

    /**
     * Retourne la liste des tâches
     * @return {JSX.Element[]}
     */
    displayTasks() {
        return partie.tasks.map((t) => {
            if (partie.tacheDepart && !completed.has(partie.tacheDepart.id) && partie.tacheDepart.id !== t.task.id) return undefined;
            if (partie.etat === "terminee" && partie.tacheFin && t.task.id !== partie.tacheFin.id) return undefined;
            if (t.task.desactivee) return undefined;
            return (<div className={style.li} key={t.task.id}><ItemTask setTask={this.props.setTask} task={t.task}/></div>);
        });
    }

    /**
     * Trie les tâches en fonction du paramètre choisi
     * @param e {Event}
     */
    sortTasks(e) {
        switch (e.target.value) {
            //trie en fonction du nombre de points données par la tâche
            case "0":
                partie.tasks.sort((a, b) => {
                    if (a.task.nbPointsGagnes < b.task.nbPointsGagnes) return 1;
                    if (a.task.nbPointsGagnes === b.task.nbPointsGagnes) return 0;
                    return -1;
                });
                break;
            // Trie en fonction du type de réponse
            case "1":
                partie.tasks.sort((a, b) => {
                    if (a.task.typeReponse < b.task.typeReponse) return 1;
                    if (a.task.typeReponse === b.task.typeReponse) return 0;
                    return -1;
                });
                break;
            // trie en fonction de la distance à la tâche
            // (tâches géolocalisées devant les autres tâches)
            case "2":
                partie.tasks.sort((a, b) => {
                    if (a.task.geolocalise && b.task.geolocalise) {
                        const da = distance(a.task);
                        const db = distance(b.task);
                        if (da > db) return 1;
                        if (da === db) return 0;
                        return -1;
                    }
                    if (a.task.geolocalise) return -1;
                    if (b.task.geolocalise) return 1;
                    return 0;
                });
                break;
            // trie par catégorie, puis par priorité
            case "3":
                partie.tasks.sort((a, b) => {
                    if (a.task.categorie && b.task.categorie) {
                        if (a.task.categorie.id > b.task.categorie.id) return 1;
                        if (a.task.categorie.id === b.task.categorie.id) return 0;
                        return -1;
                    }
                    if (b.task.categorie) return 1;
                    if (a.task.categorie) return -1;
                    if (a.task.priorite < b.task.priorite) return 1;
                    if (a.task.priorite === b.task.priorite) {
                        if (a.task.id < b.task.id) return 1;
                        if (a.task.id === b.task.id) return 0;
                        return -1;
                    }
                    return -1;
                });
                break;
            // trie par priorité d'affichage
            default:
                partie.tasks.sort((a, b) => {
                    if (a.task.priorite < b.task.priorite) return 1;
                    if (a.task.priorite === b.task.priorite) {
                        if (a.task.id < b.task.id) return 1;
                        if (a.task.id === b.task.id) return 0;
                        return -1;
                    }
                    return -1;
                });
                break;
        }
        this.setState({sortOption: e.target.value});
    }

    render() {
        return (
            <div className={style.bottom}>
                <div className={style.grip} onClick={this.handleDragClick}><FontAwesomeIcon
                    icon={this.state.opened ? faAngleDown : faAngleUp}/></div>
                {this.state.opened ? (
                        <>
                            <select className={style.select} onChange={this.sortTasks} value={this.state.sortOption}>
                                <option value={-1}>{localStorage.getItem("language") === "fr" ? "--- Options de tri ---" : "--- Sort options ---"}</option>
                                <option value={0}>{localStorage.getItem("language") === "fr" ? "Nombre de points" : "Number of points"}</option>
                                <option value={1}>{localStorage.getItem("language") === "fr" ? "Type de réponse" : "Answer type"}</option>
                                <option value={2}>Distance</option>
                                <option value={3}>{localStorage.getItem("language") === "fr" ? "Catégorie" : "Category"}</option>
                            </select>
                            <Masonry breakpointCols={this.state.nbCol} className={style.ul}>{this.displayTasks()}</Masonry>
                        </>) : (
                    <h3 onClick={this.handleDragClick} className={style.h3}>{localStorage.getItem("language") === "fr" ? "Liste des tâches" : "Task list"}</h3>)}
            </div>
        );
    }
}