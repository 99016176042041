import style from "./ReponseLibre.module.css";
import Swal from "sweetalert2";
import {answer, emit} from "../../../model";
import {Jingle} from "../../../Jingle";
import {ReponseGeolocalisee} from "../ReponseGeolocalisee";
import {popup} from "../Reponse";

/**
 * Affiche l'interface de réponse pour une tâche avec une réponse libre (texte)
 */
export class ReponseLibre extends ReponseGeolocalisee {
    constructor(props) {
        super(props);
        let trad = this.props.task.traductions.find(traduction => traduction.language === localStorage.getItem("language"));
        if (!trad)
            trad = this.props.task.traductions.find(tr => tr.language === "en");
        if (!trad)
            trad = this.props.task.traductions[0];
        this.state = {answer: "", trad};
        this.handleChange = this.handleChange.bind(this);
        this.send = this.send.bind(this);
    }

    componentWillUnmount() {
        window.removeEventListener("click", this.props.close);
    }

    handleChange(e) {
        this.setState({answer: e.target.value});
    }

    /**
     * Envoie la réponse
     */
    send() {
        if (this.state.answer.length === 0) {
            const title = localStorage.getItem("language") === "fr" ? "Écrivez une réponse" : "Write an answer";
            Swal.mixin({toast: true, showConfirmButton: false}).fire({icon: "error", title, position: "top-right", timer: 1000}).then();
            return;
        }
        if (!answer({task: this.props.task, answer: this.state.answer})) return;
        emit("confettis", true);
        Jingle(true);
        const title = localStorage.getItem("language") === "fr" ? "Réponse enregistrée" : "Answer saved";
        this.setState({repondu: true, title, text: this.state.trad.messageSuccess});
        setTimeout(() => window.addEventListener("click", this.props.close, 0));
    }

    render() {
        if (this.state.repondu) {
            return popup(this);
        }
        return (
            <div className={style.div}>
                <input className={style.input} onInput={this.handleChange} maxLength={254} type={"text"} placeholder={localStorage.getItem("language") === "fr" ? "Réponse" : "Answer"}/>
                {this.state?.text ?? ""}
                <button className={style.btn} onClick={this.send}>{localStorage.getItem("language") === "fr" ? "Envoyer" : "Send"}</button>
            </div>
        );
    }
}