//import reportWebVitals from './reportWebVitals';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {App} from './App';
import {Menu} from "./Menu";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {Auth} from "./Auth";
import Swal from "sweetalert2";
import {Chat} from "./Chat";
import {Help} from "./Help";
import {Classement} from "./Classement";
import {Settings} from "./Settings";
import {
    socket,
    partie,
    equipe,
    position,
    emit,
    setEquipe,
    escapeHtml,
    apiUrl,
    get_ask_language, set_ask_language
} from "./model";
import {Conversation} from "./Chat/Conversation";
import {Inscription} from "./Inscription";
import {RedirectTask} from "./RedirectTask";

window.URL = window.URL ?? window.webkitURL;

Auth();

/**
 * Première géolocalisation
 * @type {number}
 */
const inter = setInterval(() => {
    if (position.lat) clearInterval(inter);
    navigator.geolocation.getCurrentPosition((pos) => {
        position.lat = pos.coords.latitude;
        position.lon = pos.coords.longitude;
        position.precision = pos.coords.accuracy;
        socket.emit("position", {
            partie: partie.id,
            team: equipe.code,
            position: {lat: position.lat, lon: position.lon}
        });
    }, console.error);
}, 500);

// Met à jour la position de l'équipe dès qu'elle change
navigator.geolocation.watchPosition(send_pos, console.log, {enableHighAccuracy: true, maximumAge: 0});

/**
 * Envoie la position
 * @param pos {GeolocationPosition}
 */
function send_pos(pos) {
    position.lat = pos.coords.latitude;
    position.lon = pos.coords.longitude;
    position.precision = pos.coords.accuracy;
    socket.emit("position", {partie: partie.id, team: equipe.code, position: {lat: position.lat, lon: position.lon}});
    emit("position");
}

/**
 * Le socket n'a pas réussi à se connecter
 */
socket.on("connect_error", () => {
    setTimeout(() => {
        socket.connect();
    }, 1_000);
});

/**
 * Le socket est déconnecté
 */
socket.on("disconnect", (reason) => {
    const title = localStorage.getItem("language") === 'fr'
        ? "Vous êtes en hors ligne"
        : "You are currently offline";
    const text = localStorage.getItem("language") === 'fr'
        ? "Ne rafraîchissez pas l'application, vous perdriez des tâches non sauvegardées"
        : "Do not refresh the application, you would lose unsaved tasks";

    Swal.mixin({toast: true, showConfirmButton: false}).fire({icon: "warning", title, text, position: "top", timer: 5000}).then();

    if (reason === "io server disconnect") socket.connect();
    emit("disconnect");
});

/**
 * Le socket est connecté
 */
socket.on("connect", () => {
    //Première connexion, l'équipe n'est pas encore authentifiée
    if (!equipe.code || equipe.code === "") return;
    const title = localStorage.getItem("language") === 'fr'
        ? "Vous êtes en ligne !"
        : "You are online !";

    Swal.mixin({toast: true, showConfirmButton: false}).fire({icon: "success", title, position: "top", timer: 3000}).then();
    socket.emit("re_connect", {partie: partie.id, team: equipe.code});
    emit("reconnect");
});

/**
 * Des paramètres d'une équipe ont changé (visibilité sur la carte, dans le classement etc)
 */
socket.on("hide", () => {
    socket.emit("equipes", +localStorage.getItem("idPartie"));
});

/**
 * La partie est lancée
 */
socket.once("start", () => {
    partie.etat = "en cours";
    let text = partie.texteDebut;
    if (!text) text = localStorage.getItem("language") === "fr"
        ? "Vous pouvez désormais répondre à des tâches"
        : "You can now answer some tasks";
    Swal.fire({
        icon: !!partie.icon ? undefined : "info",
        iconHtml : partie.icon ? `<img style="max-width: 100%; max-height: 100%" src="${apiUrl}/${partie.icon}" alt="">` : undefined,
        title: localStorage.getItem("language") === "fr"
            ? "La partie a commencé"
            : "The game started",
        text,
        customClass: {
            icon: "swalIconElem",
        },
        //removes the black border
        didOpen() {
            document.getElementsByClassName("swalIconElem")[0].firstElementChild.classList.add("no_border");
        }
    });
});

/**
 * La partie est terminée
 */
socket.once("end", () => {
    partie.etat = "terminee";
    let text = partie.texteFin;
    if (!text) text = localStorage.getItem("language") === "fr"
        ? "Rendez vous au lieu de fin"
        : "Meet everyone at the meeting point";
    Swal.fire({
        icon: partie.icon ? undefined : "info",
        iconHtml : partie.icon ? `<img style="max-width: 100%; max-height: 100%" src="${apiUrl}/${partie.icon}" alt="">` : undefined,
        title: localStorage.getItem("language") === "fr"
            ? "La partie est finie"
            : "The game is over",
        text,
        customClass: {
            icon: "swalIconElem",
        }
    });
});

/**
 * Réception de la partie
 */
socket.on("partie", p => {
    for (const t in p)
        partie[t] = p[t];
    partie.equipes?.forEach(e => {
        e.position ??= {lat: 0, lon: 0}
        if (e.id === equipe.id) {
            for (const prop in e) equipe[prop] = e[prop];
        }
    });
    if (p.customBorderColor)
        document.documentElement.style.setProperty("--border", p.customBorderColor);
    if (p.customBgColor)
        document.documentElement.style.setProperty("--bg-color", p.customBgColor);
    emit("partie", partie);
    emit("refresh");
});

/**
 * Une nouvelle équipe est créée
 */
socket.on("new team", equipe => {
    partie.equipes ??= [];
    equipe.position ??= {lat: 0, lon: 0};
    partie.equipes.push(equipe);
});

/**
 * La position d'une équipe a changé
 */
socket.on("position", ({equipeId, position}) => {
    if (equipeId === equipe.id) return ;
    const e = partie.equipes.find(e => e.id === equipeId);
    if (!e) return socket.emit("equipes", localStorage.getItem("idPartie"));
    e.position = position;
    emit("pos");
});

/**
 * Réception de toutes les équipes dans la partie
 */
socket.on("equipes", eq => {
    for (const e of eq) {
        const equipe = partie.equipes.find(eq => eq.id === e.id);
        if (!equipe) {
            partie.equipes.push(e);
        } else {
            for (const prop in e) {
                equipe[prop] = e[prop];
            }
        }
    }
    emit("equipes");
});

/**
 * Réception de toutes les tâches (et catégories) de la partie
 */
socket.on('get_tasks', ({tasks, categories}) => {
    tasks.sort((a, b) => {
        if (a.task.priorite < b.task.priorite) return 1;
        if (a.task.priorite === b.task.priorite) {
            if (a.task.id < b.task.id) return 1;
            if (a.task.id === b.task.id) return 0;
            return -1;
        }
        return -1;
    });
    partie.tasks = tasks;
    partie.categories = categories;
    for (const t of tasks) {
        if (!t.task.categorie) continue;
        t.task.categorie = categories.find(c => c.id === t.task.categorie.id);
    }
    emit("get_tasks");
});

/**
 * Réception du temps avant que la partie ne se lance automatiquement
 */
socket.once("start_in", async (time) => {
    await Swal.fire({
        icon: partie.icon ? undefined : "info",
        iconHtml : partie.icon ? `<img style="max-width: 100%; max-height: 100%" src="${apiUrl}/${partie.icon}" alt="">` : undefined,
        title: localStorage.getItem("language") === "fr" ? "La partie va commencer" : "The game will start soon",
        allowOutsideClick: false,
        showCloseButton: false,
        showConfirmButton: false,
        timer: time,
        timerProgressBar: true,
        customClass: {
            icon: "swalIconElem",
        }
    });
});

/**
 * Affichage d'une erreur
 */
socket.on("error", msg => {
    Swal.fire({
        icon: "error",
        title: msg,
        text: localStorage.getItem("language") === "en" ? "Contact the admin if the problem persists" : "contactez l'admin si le problème persiste"
    });
});

/**
 * Envoi d'un signal
 * todo
 */
setInterval(() => {
    socket.emit("signal", equipe.code);
}, 5_000);

if (!localStorage.getItem("language")) {
    set_ask_language(true);
    localStorage.setItem("language", "en");
} else set_ask_language(false);

socket.once("auth", async (err, equipe) => {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (err) {
        const text = localStorage.getItem("language") === "fr"
            ? "Flashez à nouveau le QR code, puis contactez l'admin si le problème persiste"
            : "Flash the QR code again, and contact the admin if the problem persists";
        await Swal.fire({
            icon: "error",
            title: err,
            text
        });
        return;
    }
    setEquipe(equipe);
    if (urlParams.get("idPartie") && urlParams.get("idPartie").length !== 0)
        localStorage.setItem("idPartie", urlParams.get("idPartie"));
    if (urlParams.get("code") && urlParams.get("code").length !== 0)
        localStorage.setItem("code", urlParams.get("code"));
    const title = localStorage.getItem("language") === "fr" ? "Bienvenue " : "Welcome ";
    const text = localStorage.getItem("language") === "fr" ? "Vous êtes connectés !" : "You are connected !";
    if (partie && partie.tasks?.length === 0)
        socket.emit("get_tasks", +(localStorage.getItem("idPartie")));

    await Swal.fire({
        icon: !!partie.icon ? undefined : "success",
        iconHtml : !!partie.icon ? `<img style="max-width: 100%; max-height: 100%" src="${apiUrl}/${partie.icon}" alt="">` : undefined,
        title: escapeHtml(title + equipe.name),
        text,
        customClass: {
            icon: !!partie.icon ? "swalIconElem" : undefined,
        }
    });

    const l = localStorage.getItem("language");
    if (get_ask_language()) {
        Swal.fire({
            title: l === 'fr' ? "L'interface est en français." : `The interface is displayed in english.`,
            text: l === "fr" ? "Voulez vous changer la langue ?" : "Do you want to change the language ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: l === "fr" ? "Changer" : "Change",
            cancelButtonText: l === "fr" ? "Ne pas changer" : "Don't change",
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                emit("redirect", '/settings');
            }
        });
    }
    emit("refresh");
});

socket.on("get_partie", Auth);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
      <BrowserRouter>
          <Routes>
              <Route path={"/"} element={<App/>}>
                  <Route index element={<Menu/>}/>
                  <Route path={"chat"} element={<App/>}>
                      <Route index element={<Chat/>}/>
                      <Route path={":id"} element={<Conversation />}/>
                  </Route>
                  <Route path={"task"} element={<App />}>
                    <Route path={":id"} element={<RedirectTask />}/>
                  </Route>
                  <Route path={"help"} element={<Help/>}/>
                  <Route path={"classement"} element={<Classement/>}/>
                  <Route path={"settings"} element={<Settings/>}/>
                  <Route path={"partie"} element={<Inscription/>}/>
              </Route>
          </Routes>
      </BrowserRouter>
  </React.StrictMode>
);


/* ====================== */
/* === FULLSCREEN API === */
/* ====================== */


/* cf. https://j.hn/native-fullscreen-javascript-api-plus-jquery-plugin/ */
(function() {
    const fullScreenApi = {
            supportsFullScreen: false,
            isFullScreen: function() { return false; },
            requestFullScreen: function() {},
            cancelFullScreen: function() {},
            fullScreenEventName: '',
            prefix: ''
        },
        browserPrefixes = 'webkit moz o ms khtml'.split(' ');
    // check for native support
    if (typeof document.cancelFullScreen != 'undefined') {
        fullScreenApi.supportsFullScreen = true;
    } else {
        // check for fullscreen support by vendor prefix
        for (var i = 0, il = browserPrefixes.length; i < il; i++ ) {
            fullScreenApi.prefix = browserPrefixes[i];
            if (typeof document[fullScreenApi.prefix + 'CancelFullScreen' ] != 'undefined' ) {
                fullScreenApi.supportsFullScreen = true;
                break;
            }
        }
    }
    // update methods to do something useful
    if (fullScreenApi.supportsFullScreen) {
        fullScreenApi.fullScreenEventName = fullScreenApi.prefix + 'fullscreenchange';
        fullScreenApi.isFullScreen = function() {
            switch (this.prefix) {
                case '':
                    return document.fullScreen;
                case 'webkit':
                    return document.webkitIsFullScreen;
                default:
                    return document[this.prefix + 'FullScreen'];
            }
        }
        fullScreenApi.requestFullScreen = function(el) {
            return (this.prefix === '') ? el.requestFullScreen() : el[this.prefix + 'RequestFullScreen']();
        }
        fullScreenApi.cancelFullScreen = function(el) {
            return (this.prefix === '') ? document.cancelFullScreen() : document[this.prefix + 'CancelFullScreen']();
        }
    }
    // export api
    window.fullScreenApi = fullScreenApi;
})();

/* GENERATED CODE */

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
