import style from "./Menu.module.css";
import React from "react";
import {TaskList} from "../TaskList";
import {Task} from "../Task";
import {apiUrl, completed, emit, equipe, getTask, off, on, partie, position, socket, team_locations} from "../model";
import {Timer} from "../Timer";
import {Loader} from '@googlemaps/js-api-loader';
import {gmaps_key} from "../config";
import {Popup} from "./Popup";
import pop from "./Popup/Popup.module.css";
import {MapMenu} from "../MapMenu";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBan} from "@fortawesome/free-solid-svg-icons";

export class Menu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {task: getTask(), center: {lat: isNaN(+partie.lat)? 0 : +partie.lat, lng: isNaN(+partie.lon)? 0 : +partie.lon}, zoom: +partie.zoom ?? 0};
        this.map = null;
        this.DOM_map = React.createRef();
        this.panel = React.createRef();
        this.setTask = this.setTask.bind(this);
        this.removeTask = this.removeTask.bind(this);
        this.setPartie = this.setPartie.bind(this);
        this.createMarkers = this.createMarkers.bind(this);
        this.removePopup = this.removePopup.bind(this);
        this.update_personal_pin = this.update_personal_pin.bind(this);
        this.createMap = this.createMap.bind(this);
        this.createTeamPins = this.createTeamPins.bind(this);
        this.center = this.center.bind(this);
        this.reinit = this.reinit.bind(this);
        this.guide = this.guide.bind(this);
        this.stop = this.stop.bind(this);
        this.zoom = this.zoom.bind(this);
        this.unzoom = this.unzoom.bind(this);
    }

    componentDidMount() {
        on("equipes", this.createTeamPins);
        on("partie", this.setPartie);
        on("position", this.update_personal_pin);
        on("nbPoints", this.createMarkers);
        on("pos", this.createTeamPins);
        socket.on("get_tasks", this.setPartie);
        new Loader({
            apiKey: `${gmaps_key}`,
            version: "weekly",
            libraries: ["places"]
        }).load().then(g => {
            window.google = g;
            this.createMap();
        }).catch(console.error);
    }

    componentWillUnmount() {
        off("equipes", this.createTeamPins);
        off("nbPoints", this.createMarkers);
        off("position", this.update_personal_pin);
        off("partie", this.setPartie);
        off("pos", this.createTeamPins);
        socket.off("get_tasks", this.setPartie);
    }

    /**
     * Affiche la carte gmaps
     */
    createMap() {
        if (!this.DOM_map.current) return setTimeout(() => {
            this.createMap();
        }, 100);
        this.map = new window.google.maps.Map(this.DOM_map.current, {
            ...this.state,
            styles: [
                {
                    "featureType": "administrative",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "administrative.land_parcel",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "administrative.neighborhood",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "stylers": [
                        {
                            "visibility": "on"
                        },
                        {
                            "weight": 8
                        }
                    ]
                },
                {
                    "featureType": "landscape.natural.terrain",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                }
            ],
            streetViewControl: false,
            mapTypeControl: false,
            zoomControl: false,
            fullscreenControl: false,
            language: localStorage.getItem("language"),
        });
        this.createMarkers();
        this.createTeamPins();
        this.update_personal_pin();
    }

    /**
     * Met tout le menu à jour
     */
    setPartie() {
        this.reinit();
        this.createMarkers();
        this.createTeamPins();
    }

    /**
     * Affiche une tâche à la place du menu global
     * @param task
     */
    setTask(task) {
        this.setState({ task });
    }

    removePopup() {
        this.setState({popup: false});
    }

    /**
     * Affiche tous les markers (pins) sur la carte des tâches
     */
    createMarkers() {
        if (!this.map) return;
        for (const task of partie.tasks) {
            const t = task.task;
            t.marker?.setMap(null);
            if (partie.etat === "terminee") {
                if (task.id !== partie.tacheFin?.id) continue;
            }
            if (partie.tacheDepart && !completed.has(partie.tacheDepart.id) && t.id !== partie.tacheDepart.id) continue;
            if (!t.geolocalise) continue;

            let trad = t.traductions.find(tr => tr.language === localStorage.getItem("language"));
            if (!trad) trad = t.traductions.find(tr => tr.language === "en");
            if (!trad) trad = t.traductions[0];
            const image = new Image();
            image.src = `${apiUrl}/${completed.has(t.id) ? "/task_icons/completed.svg" : t.icon}`;
            image.onload = () => {
                const ratio = image.width / image.height;
                const icon = {
                    url: `${apiUrl}/${completed.has(t.id) ? "/task_icons/completed.svg" : t.icon}`,
                    scaledSize: new window.google.maps.Size(ratio < 1 ? 40 * ratio : 40, ratio > 1 ? 40 * ratio : 40),
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(ratio < 1 ? 20 * ratio : 20, ratio > 1 ? 40 * ratio : 40)
                };
                t.marker = new window.google.maps.Marker({
                    position: {lat: +t.latitude, lng: +t.longitude},
                    map: this.map,
                    title: trad.title,
                    icon
                });
                t.marker.addListener("click", () => {
                    this.setState(() => ({task: t}));
                });
            }
        }
    }

    /**
     * Change la position du marker (pin) de l'équipe sur la carte gmaps
     */
    update_personal_pin() {
        if (!this.map || !position.lat || position.lat === 0 || !position.lon || position.lon === 0)
            return setTimeout(() => {
                this.update_personal_pin();
            }, 500);

        const image = new Image();
        image.src = `${apiUrl}/${equipe?.icon}?update=${Date.now()}`;
        image.onload = () => {
            const ratio = image.width / image.height;
            const icon = {
                url: `${apiUrl}/${equipe?.icon}`,
                scaledSize: new window.google.maps.Size(ratio < 1 ? 40 * ratio : 40, ratio > 1 ? 40 * ratio : 40),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(ratio < 1 ? 20 * ratio : 20, ratio > 1 ? 40 * ratio : 40)
            };
            const pin = new window.google.maps.Marker({
                position: {lat: +position.lat, lng: +position.lon},
                map: this.map,
                title: (localStorage.getItem("language") === "fr") ? "Vous" : "You",
                icon,
                zIndex: 999
            });
            // Otherwise, it blinks
            const p = position.pin;
            setTimeout(() => {p?.setMap(null)}, 60);
            position.pin = pin;
            const iw = new window.google.maps.InfoWindow({
                content: `<h3 style="margin: 0">${equipe.name}</h3><br><p style="margin: 0">${localStorage.getItem("language") === "fr" ? "Votre équipe" : 'Your team'}</p>`
            });
            pin.addListener("click", () => {
                iw.open({
                    anchor: pin,
                    map: this.map,
                    shouldFocus: false
                });
            });
        }
    }

    /**
     * Met à jour (ou crée) les markers (pins) des autres équipes sur la carte
     */
    createTeamPins() {
        if (!window.google || !this.map || !partie.equipes || partie.equipes.length === 1) return setTimeout(() => {this.createTeamPins();}, 1000);

        for (const e of partie.equipes ?? []) if (!team_locations.get(e.id) && e.id !== equipe.id) team_locations.set(e.id, null);
        team_locations.forEach((l, id) => {
            const equipe = partie.equipes?.find(e => e.id === id);
            if (l && (l.getMap() !== this.map || !equipe || !equipe.visibleCarte))
                l.setMap(null);
            if (!equipe.visibleCarte) return;
            if (!equipe.position?.lat || !equipe.position?.lon) return;

            // création d'un marker
            if (!l) {
                const content = document.createElement("div");
                const h3 = document.createElement("h3");
                h3.innerText = equipe.name;
                h3.style.width = "100%";
                h3.style.textAlign = "center";
                content.appendChild(h3);
                content.appendChild(document.createElement("br"));
                const p = document.createElement('p');
                p.innerText = localStorage.getItem("language") === 'fr' ? "Cliquez pour tchatter" : "Click to chat";
                content.appendChild(p);
                content.onclick = () => emit("redirect", "/chat/" + equipe.id);
                content.style.cursor = "pointer";
                const iw = new window.google.maps.InfoWindow({
                    content
                });
                const image = new Image();
                image.src = `${apiUrl}/${equipe.icon}`;
                image.onload = () => {
                    const ratio = image.width / image.height;
                    const icon = {
                        url: `${apiUrl}/${equipe.icon}`,
                        scaledSize: new window.google.maps.Size(ratio < 1 ? 20 * ratio : 20, ratio > 1 ? 20 * ratio : 20),
                        origin: new window.google.maps.Point(0, 0),
                        anchor: new window.google.maps.Point(ratio < 1 ? 10 * ratio : 10, ratio > 1 ? 20 * ratio : 20)
                    };
                    const pin = new window.google.maps.Marker({
                        position: {lat: +(equipe.position?.lat) ?? 0, lng: +(equipe.position?.lon) ?? 0},
                        map: this.map,
                        title: equipe.name,
                        icon,
                        zIndex: 800
                    });
                    pin.addListener("click", () => {
                        iw.open({
                            anchor: pin,
                            map: this.map,
                            shouldFocus: false
                        });
                    });
                    team_locations.set(equipe.id, pin);
                };

            // mise à jour du marker existant
            } else {
                l.setPosition({lat: +equipe.position.lat, lng: +equipe.position.lon});
                const image = new Image();
                image.src = `${apiUrl}/${equipe.icon}`;
                image.onload = () => {
                    const ratio = image.width / image.height;
                    l.setIcon({
                        url: `${apiUrl}/${equipe.icon}`,
                        scaledSize: new window.google.maps.Size(ratio < 1 ? 20 * ratio : 20, ratio > 1 ? 20 * ratio : 20),
                        origin: new window.google.maps.Point(0, 0),
                        anchor: new window.google.maps.Point(ratio < 1 ? 10 * ratio : 10, ratio > 1 ? 20 * ratio : 20)
                    });
                };
                l.setMap(this.map);
            }
        });
    }

    removeTask() {
        this.setState({task: null});
    }

    /**
     * Affiche l'équipe au centre de la carte, et zoom dessus
     */
    center() {
        this.setState({center: {lat: +position.lat, lng: +position.lon}, zoom: 19}, () => {
            this.map?.setCenter(this.state.center);
            this.map?.setZoom(this.state.zoom);
        });
        this.update_personal_pin();
        this.createTeamPins();
    }

    /**
     * Affiche la carte comme elle a été définie dans la partie
     */
    reinit() {
        this.setState({center: {lat: +partie.lat ?? 0, lng: +partie.lon ?? 0}, zoom: +partie.zoom ?? 0}, () => {
            this.map?.setCenter(this.state.center);
            this.map?.setZoom(this.state.zoom);
        });
        this.createTeamPins();
        this.update_personal_pin();
    }

    /**
     * Zoom sur la carte
     */
    zoom() {
        if (!this.map) return;
        this.map.setZoom(this.map.getZoom() + 1);
    }

    /**
     * dézoome la carte
     */
    unzoom() {
        if (!this.map) return;
        this.map.setZoom(this.map.getZoom() - 1);
    }

    /**
     * Affiche un chemin entre l'équipe et la tache choisie
     */
    guide() {
        this.state.directionsRenderer?.setMap(null);
        if (!this.map) return;
        const task = this.state.popup || this.state.task;
        this.removePopup();
        const options = {
            origin: {lat: position.lat, lng: position.lon},
            destination: {lat: +task.latitude, lng: +task.longitude},
            travelMode: 'WALKING',
            unitSystem: window.google.maps.UnitSystem.METRIC,
            provideRouteAlternatives: false
        }
        const directionsService = new window.google.maps.DirectionsService();
        const directionsRenderer = new window.google.maps.DirectionsRenderer();
        this.setState({directionsRenderer}, () => {
            directionsRenderer.setPanel(this.panel.current);
        });
        directionsRenderer.setMap(this.map);
        directionsService.route(options, (res, status) => {
            if (status === 'OK') {
                directionsRenderer.setDirections(res);
            } else {
                this.stop();
            }
        });
    }

    /**
     * N'affiche plus le chemin entre l'équipe et la tâche
     */
    stop() {
        this.state.directionsRenderer.setMap(null);
        this.state.directionsRenderer.setPanel(null);
        this.setState({directionsRenderer: null});
        this.center();
    }

    render() {
        this.createMarkers();
        this.createTeamPins();
        this.update_personal_pin();
        return (
            <div>
                <Timer/>
                {this.state.task ? <Task guide={this.guide} removeTask={this.removeTask} task={this.state.task}/> : ""}
                <div className={style.map} ref={this.DOM_map}/>
                <MapMenu zoom={this.zoom} unzoom={this.unzoom} reinit={this.reinit} center={this.center}/>
                {this.state.popup ? (
                    <div className={pop.div}><Popup task={this.state.popup} guide={this.guide}
                                                    removePopup={this.removePopup} setTask={this.setTask}/></div>
                ) : (<TaskList setTask={this.setTask}/>)}
                {
                    this.state.directionsRenderer ? <div className={style.panel} ref={this.panel}>
                        <button onClick={this.stop} className={style.stop}><FontAwesomeIcon icon={faBan}/>
                        </button>
                    </div> : ""
                }
            </div>
        );
    }
}