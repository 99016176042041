import s from './Message.module.css';
import {date_connexion, equipe, partie} from "../../../../model";

/**
 * Affiche un message
 * @param message {{sender: string, message: string}}
 * @return {JSX.Element}
 * @constructor
 */
export function Message({message}) {
    let name = partie.equipes?.find(p => p.id === message.sender)?.name;
    if (!name && message.sender === "admin") name = "Admin";
    if (name) name = name.substring(0, 25);
    else name = localStorage.getItem("language") === "fr" ? "Anonyme" : "Anonymous";
    const style_p = {
        marginTop: 0,
        fontWeight: "bold",
        color: name === "Admin" ? "red" : 'var(--bg-color)'
    };
    if (name === "Admin") {
        style_p.fontSize = "1.2rem";
    }
    return (
        <div className={`${s.message} ${(message.sender === equipe.id || (!equipe.id && `${message.sender}` === `${date_connexion}`)) ? s.right : s.left}`}>{name ? <p style={style_p}>{name}</p> : ""}{message.message}</div>
    );
}