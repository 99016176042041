import style from "./Carousel.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {Asset} from "../Asset";
import React from "react";

/**
 * affiche un carousel
 */
export class Carousel extends React.Component {

    constructor(props) {
        super(props);
        this.carouselNext = null;
        this.state = {lien: this.props.liens[0], index: 0};
        this.isPlaying = false;
        this.handleNext = this.handleNext.bind(this);
        this.handlePrev = this.handlePrev.bind(this);
        this.setIsPlaying = this.setIsPlaying.bind(this);
        this.switch_to = this.switch_to.bind(this);
    }

    componentDidMount() {
        this.carouselNext = setInterval(() => {
            if (!this.isPlaying)
                this.handleNext();
        }, 5_000);
    }

    componentWillUnmount() {
        clearInterval(this.carouselNext);
    }

    /**
     * Change l'image / vidéo / audio affiché pour le suivant
     * @param e {Event?}
     */
    handleNext(e) {
        e?.stopPropagation();
        clearInterval(this.carouselNext);
        this.carouselNext = setInterval(() => {
            this.handleNext();
        }, 5_000);
        if (this.props.liens.length === 0) return;
        this.setState(state => {
            let index = (state.index + 1) % this.props.liens.length;
            while (index < 0) index += this.props.liens.length;
            return {lien: this.props.liens[index], index};
        });
    }

    /**
     * Affiche l'image / vidéo / audio précédent(e)
     * @param e {Event?}
     */
    handlePrev(e) {
        e?.stopPropagation();
        clearInterval(this.carouselNext);
        this.carouselNext = setInterval(() => {
            this.handleNext();
        }, 5_000);
        if (this.props.liens.length === 0) return;
        this.setState(state => {
            let index = (this.props.liens.indexOf(state.lien) - 1) % this.props.liens.length;
            while (index < 0) index += this.props.liens.length;
            return {lien: this.props.liens[index]};
        });
    }

    /**
     * Change l'image / vidéo/ audio affiché(e) pour le lien donnée en paramètres
     * @param l {string}
     * le lien
     */
    switch_to(l) {
        clearInterval(this.carouselNext);
        this.carouselNext = setInterval(() => {
            this.handleNext();
        }, 5_000);
        this.setState({lien: l});
    }

    /**
     * Utilisé pour bloquer le changement automatique si une vidéo ou un audio est joué
     * @param val {boolean}
     * true si une vidéo / audio se joue, false sinon
     */
    setIsPlaying(val) {
        this.isPlaying = val;
    }

    render() {
        if (this.props.liens?.length ===  0) return;
        return (
            <div className={style.full} style={this.props._style}>
                <div className={style.asset}>
                    <button onClick={this.handlePrev} className={`${style.btnNav} ${style.left}`}><FontAwesomeIcon icon={faArrowLeft}/></button>
                    <Asset lien={this.state.lien} setPlaying={this.setIsPlaying}/>
                    <button onClick={this.handleNext} className={`${style.btnNav} ${style.right}`}><FontAwesomeIcon icon={faArrowRight}/></button>
                </div>
                <div className={style.bottom}>{this.props.liens.map((l) => {return <button key={this.props.liens.indexOf(l)} onClick={() => {this.switch_to(l)}} className={`${style.bottom_btn} ${this.state.lien === l ? style.active : ""}`}/>})}</div>
            </div>
        );
    }
}