import React from "react";
import {faCircleArrowLeft, faHand} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import style from "./QuestionTask.module.css";
import {Carousel} from "../Carousel";
import {Reponse} from "./Reponse";
import {apiUrl, equipe} from "../model";
import {JokerList} from "../JokerList";

/**
 * Affiche la question d'une tâche et l'interface pour y répondre (ou les Jokers)
 */
export class QuestionTask extends React.Component {
    constructor(props) {
        super(props);
        let trad = props.task.traductions.find(t => t.language === localStorage.getItem("language"));
        if (!trad) trad = props.task.traductions.find(t => t.language === "en");
        if (!trad) trad = props.task.traductions[0];
        if (!trad.jokers) trad.jokers = [];
        trad.jokers.sort((a, b) => {
            if (a.price < b.price) return -1;
            if (a.price === b.price) return 0;
            return 1;
        });
        equipe.jokers ||= [];
        this.state = {trad, joker: false};
    }

    render() {
        return (
            <div className={style.flex}>
                <button className={style.back} onClick={this.props.back}><FontAwesomeIcon icon={faCircleArrowLeft}/></button>
                <Carousel _style={{height: "max(200px, 30%)"}} liens={this.props.task.liensFichiersPresentationTache} />
                <h3 style={{marginTop: this.props.task.liensFichiersPresentation.length === 0 ? "3rem" : "initial"}} className={style.title}>
                    {this.props.task.categorie?.icon && <img className={style.icon} src={`${apiUrl}/${this.props.task.categorie.icon}`} alt={" "}/>}{this.state.trad.title} - {this.props.task.nbPointsGagnes}pts
                </h3>
                <p className={style.presentation}>{this.state.trad.description_tache}</p>
                {this.state.joker ? (<JokerList jokers={this.state.trad.jokers} close={() => this.setState({joker: false})}/>) : <Reponse close={this.props.close} task={this.props.task}/>}
                {this.state.trad.jokers.length > 0 ? <button onClick={() => this.setState(state => ({joker: !state.joker}))} className={style.joker} style={this.state.joker ? {color: "red"} : null}><FontAwesomeIcon icon={faHand}/></button> : ""}
            </div>
        );
    }
}