import React from "react";
import {distance, off, on} from "../../model";

/**
 * Réponse si la tâche est géolocalisée (classe abstraite)
 */
export class ReponseGeolocalisee extends React.Component {
    constructor(props) {
        super(props);
        this.checkPos = this.checkPos.bind(this);
    }

    componentDidMount() {
        let text = "";
        let disabled = false;
        if (this.props.task.geolocalise) {
            disabled = distance(this.props.task) > this.props.task.distanceMax;
            if (disabled) {
                const dist = Math.round(distance(this.props.task));
                const km = dist / 1000;
                text = localStorage.getItem("language") === "fr" ?
                    `Vous êtes à ${km}m de cette tâche` :
                    `You are ${km}m away from this task`;
            }
        }
        this.setState({disabled, text});
        on('position', this.checkPos);
    }

    componentWillUnmount() {
        off("position", this.checkPos);
    }

    checkPos() {
        if (this.props.task.geolocalise) {
            const disabled = distance(this.props.task) > this.props.task.distanceMax;
            this.setState({disabled});
        }
    }

    render() {
        return (<></>);
    }
}