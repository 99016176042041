import style from "./ReponsePhoto.module.css";
import {answerPhotoVideo, emit} from "../../../model";
import {Jingle} from "../../../Jingle";
import {ReponseGeolocalisee} from "../ReponseGeolocalisee";
import {popup} from "../Reponse";

/**
 * Affiche l'interface de réponse photo
 */
export class ReponsePhoto extends ReponseGeolocalisee {

    constructor(props) {
        super(props);
        let trad = this.props.task.traductions.find(traduction => traduction.language === localStorage.getItem("language"));
        if (!trad)
            trad = this.props.task.traductions.find(tr => tr.language === "en");
        if (!trad)
            trad = this.props.task.traductions[0];
        this.state ??= {trad};
        this.handlePic = this.handlePic.bind(this);
    }

    componentWillUnmount() {
        window.removeEventListener("click", this.props.close);
    }

    /**
     * Envoie la photo prise
     * @param e {Event}
     */
    handlePic(e) {
        const form = new FormData();
        form.append("file", e.target.files[0]);
        if (answerPhotoVideo({task: this.props.task, answer: form})) {
            Jingle(true);
            emit("confettis", true);
            const title = localStorage.getItem("language") === "fr" ? "Réponse enregistrée" : "Answer saved";
            this.setState({repondu: true, title, text: this.state.trad.messageSuccess});
            setTimeout(() => window.addEventListener("click", this.props.close, 0));
        }
    }

    render() {
        if (this.state.repondu) {
            return popup(this);
        }
        return (
            <div className={style.div}>
                <label>
                    <span className={style.btn}>{localStorage.getItem("language") === 'fr' ? "Prendre une photo" : "Take a picture"}</span>
                    {this.state?.text ?? ""}
                    <input onChange={this.handlePic} className={style.invisible} type={"file"} accept={"image/*"} capture={"environment"}/>
                </label>
            </div>
        );
    }
}