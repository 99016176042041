import React from "react";
import style from "./ReponseQCM.module.css";
import {OptionQCM} from "./OptionQCM";
import {answer, emit} from "../../../model";
import {Jingle} from "../../../Jingle";
import {ReponseGeolocalisee} from "../ReponseGeolocalisee";
import {popup} from "../Reponse";

/**
 * Affiche l'interface de réponse à un QCM
 */
export class ReponseQCM extends ReponseGeolocalisee {
    constructor(props) {
        super(props);
        let trad = this.props.task.traductions.find(traduction => traduction.language === localStorage.getItem("language"));
        if (!trad)
            trad = this.props.task.traductions.find(tr => tr.language === "en");
        if (!trad)
            trad = this.props.task.traductions[0];
        const arr = [];
        while (trad.optionsQCM.length > 0) {
            arr.push(trad.optionsQCM.splice(Math.floor(Math.random() * trad.optionsQCM.length), 1)[0]);
        }
        trad.optionsQCM = arr;
        let count = 0;
        for (const o of trad.optionsQCM) {
            if (o.valide) count++;
        }
        // affiche des radio s'il n'y a qu'une réponse valide, sinon des checkboxes
        this.state = {trad, type: count === 1 ? "radio" : "checkbox" };
        this.references = trad.optionsQCM.map((o) => {return {id: o.id, ref: React.createRef()}});
        this.send = this.send.bind(this);

    }

    componentWillUnmount() {
        window.removeEventListener("click", this.props.close);
    }

    /**
     * Envoie la réponse
     */
    send() {
        let checked = this.references.find(r => r.ref.current?.checked);
        let valide = true;
        const reponses = {};
        for (const r of this.references) {
            reponses[`${r.id}`] = r.ref.current?.checked;
            valide &&= (r.ref.current?.checked === this.state.trad.optionsQCM.find(o => o.id === r.id)?.valide);
        }

        if (!checked) return;
        if (!answer({task: this.props.task, answer: JSON.stringify(reponses)})) return;
        let title;
        for (const a in this.state.reponses) {
            valide = valide && this.state.reponses[a];
        }
        Jingle(valide);
        if (localStorage.getItem("language") === "fr")
            title = valide ? "Bonne réponse !" : "Mauvaise réponse";
        else title = valide ? "Good answer !" : "Bad answer";
        emit("confettis", true);
        this.setState({repondu: true, title, text: valide ? this.state.trad.messageSuccess : this.state.trad.messageFailure});
        setTimeout(() => window.addEventListener("click", this.props.close, 0));
    }

    render() {
        if (this.state.repondu) {
            return popup(this);
        }
        return (
            <div className={style.div}>
                <div className={style.form}>
                    {this.state.trad.optionsQCM.map((o) => <OptionQCM type={this.state.type} key={ o.id } setRef={this.references.find(r => r.id === o.id).ref} option={ o } />)}
                </div>
                {this.state?.text ?? ""}
                <button disabled={this.state.disabled} className={style.btn} onClick={this.send}>{localStorage.getItem("language") === "fr" ? "Envoyer" : "Send"}</button>
            </div>
        );
    }
}