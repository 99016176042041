import {on, off, partie, emit, setTask} from "../model";
import {Component} from "react";

/**
 * Redirige l'utilisateur vers la tâche demandée (url: /task/:id)
 */
export class RedirectTask extends Component {
    constructor(props) {
        super(props);
        this.redirect = this.redirect.bind(this);
    }

    componentDidMount() {
        on("get_tasks", this.redirect);
    }

    /**
     * Redirige l'utilisateur vers la tâche
     */
    redirect() {
        setTask(partie.tasks.find(t => t.task.id === +window.location.href.split("/").at(-1))?.task);
        off("get_tasks", this.redirect);
        emit("redirect", "/");
    }

    render() {
        return (
            <div>
                {localStorage.getItem("language") === "fr" ? "Chargement ..." : "Loading ..."}
            </div>
        );
    }
}

