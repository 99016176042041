import style from "./PresentationTask.module.css";
import button from "../../TaskList/ItemTask/ItemTask.module.css";
import React from "react";
import {Carousel} from "../../Carousel";
import {completed, distance, equipe, partie, apiUrl, off, on, answers} from "../../model";

/**
 * Affiche la présentation d'une tâche
 */
export class PresentationTask extends React.Component {
    constructor(props) {
        super(props);
        let trad = props.task.traductions.find(t => t.language === localStorage.getItem("language"));
        if (!trad) trad = props.task.traductions.find(t => t.language === "en");
        if (!trad) trad = props.task.traductions[0];
        this.state = {trad};
        this.texte_tache_tempo = "";

        this.isTooFar = this.isTooFar.bind(this);
        this.update = this.update.bind(this);
    }

    componentDidMount() {
        on("received", this.update);
    }

    componentWillUnmount() {
        off("received", this.update);
    }

    update() {
        this.forceUpdate();
    }

    /**
     * Vérifie que l'équipe est suffisamment proche de la tâche pour y répondre
     * @return {boolean}
     * true si l'équipe est trop loin, false sinon
     */
    isTooFar() {
        if (this.props.task.geolocalise) {
            return (distance(this.props.task) > this.props.task.distanceMax);
        }
        return false;
    }

    /**
     * Vérifie tous les paramètres pour que l'équipe ait le droit de répondre à la tâche
     * @return {boolean}
     */
    isDisabled() {
        if (answers.find(a => a.task === this.props.task.id)) {
            this.texte_completed = localStorage.getItem("language") === "fr" ? "Vous avez déjà répondu à cette tâche" : "You already completed this task";
            return true;
        }
        this.texte_lieu_parent = "";
        this.texte_tache_tempo = "";
        const temp = partie.lieuxTemporaires.find(l => l.lieu.id === this.props.task.id);
        let disabled = !!temp;
        //tâche temporaire, vérifie qu'elle soit ouverte
        if (disabled) {
            this.temp = disabled;
            const now = Date.now();
            const debut = new Date(temp.dateOuverture).getTime() + new Date().getTimezoneOffset();
            const fin = new Date(temp.dateFermeture).getTime() + new Date().getTimezoneOffset();
            disabled = now < debut || now > fin;
            if (now < debut) {
                const m = Math.floor((((debut - now) / 1000) % 3_600) / 60);
                const h = Math.floor((((debut - now) / 1000) % 3_600_000) / 3600);
                const time = `${h > 0 ? `${h}h` : ""}${m}m`;
                this.texte_tache_tempo = localStorage.getItem("language") === "fr" ? `Cette tâche s'ouvrira dans ${time}` : `This task will open in ${time}`;
            } else if (now > fin) {
                const m = Math.floor((((now - fin) / 1000) % 3_600) / 60);
                const h = Math.floor((((now - fin) / 1000) % 3_600_000) / 3600);
                const time = `${h > 0 ? `${h}h` : ""}${m}m`;
                this.texte_tache_tempo = localStorage.getItem("language") === "fr" ? `Cette tâche est fermée depuis ${time}` : `This task is closed since ${time}`;
            } else {
                const m = Math.floor((((fin - now) / 1000) % 3_600) / 60) + 1;
                const h = Math.floor((((fin - now) / 1000) % 3_600_000) / 3600);
                const time = `${h > 0 ? `${h}h` : ""}${m}m`;
                this.texte_tache_tempo = localStorage.getItem("language") === "fr" ? `Cette tâche se fermera dans ${time}` : `This task will close in ${time}`;
            }
        }
        //Vérifie que la partie soit en cours, et que l'équipe soit suffisamment proche de la tâche.
        disabled ||= partie.etat !== "en cours" || this.isTooFar();

        // Vérifie que l'équipe n'ait pas déjà répondu à la tâche, ou bien ait le droit d'y répondre à nouveau
        if ((completed.has(this.props.task.id) && !completed.get(this.props.task.id).juste && completed.get(this.props.task.id).nombre < this.props.task.nbReponse)) {
            this.texte_completed = localStorage.getItem("language") === "fr"
                ? "Vous n'avez pas répondu correctement à cette tâche, vous pouvez réessayer"
                : "You didn't answer correctly this task, you can try again";
        }
        // Sinon, affiche qu'elle a déjà répondu
        else if ((completed.get(this.props.task.id)?.juste || completed.get(this.props.task.id)?.nombre >= this.props.task.nbReponse)) {
            this.texte_completed = localStorage.getItem("language") === "fr" ? "Vous avez déjà répondu à cette tâche" : "You already completed this task";
            disabled = true;
        }

        // Vérifie que l'équipe ait répondu à la tâche parent si elle existe (et qu'elle y a bien répondu si c'est demandé)
        const hasParent = this.props.task.lieuParent && (!completed.has(this.props.task.lieuParent.id) ||( this.props.task.bonneReponseDependance && completed.get(this.props.task.lieuParent.id) === false));
        if (hasParent) {
            const lieuParent = (partie.tasks.find(l => l.task.id === this.props.task.lieuParent.id)).task;
            if (lieuParent && this.props.task.dependance) {
                let trad_parent = lieuParent.traductions.find(t => t.language === localStorage.getItem("language"));
                if (!trad_parent) trad_parent = lieuParent.traductions.find(t => t.language === "en");
                if (!trad_parent) trad_parent = lieuParent.traductions[0];
                this.texte_lieu_parent = localStorage.getItem("language") === "fr"
                    ? `Vous devez répondre à la tâche ${trad_parent.title} avant de répondre à celle ci`
                    : `You have to answer the task ${trad_parent.title} before answering this one`;
                disabled = true;
                if (this.props.task.bonneReponseDependance && completed.get(this.props.task.lieuParent.id) === false) {
                    this.texte_lieu_parent = localStorage.getItem("language") === "fr"
                        ? `Vous n'avez pas répondu correctement à la tâche ${trad_parent.title}`
                        : `You didn't answer correctly the task ${trad_parent.title}`;
                    this.disabled = true;
                }
            }
        }

        //Vérifie que l'équipe ait assez de points pour répondre à la tâche
        disabled ||= this.props.task.nbPointsMin > 0 && this.props.task.nbPointsMin > equipe.points;
        // Vérifie que la partie est terminée et que la tâche est la tâche de fin
        if (partie.etat === "terminee" && partie.tacheFin && this.props.task.id === partie.tacheFin.id) return false;
        return disabled;
    }

    render() {
        const d = this.isDisabled();
        return (
            <>
                <Carousel _style={{height: "max(200px, 30%)"}} liens={this.props.task.liensFichiersPresentation}/>
                <h3 style={{marginTop: this.props.task.liensFichiersPresentation.length === 0 ? "3rem" : "initial"}} className={style.title}>
                    {this.props.task.categorie?.icon && <img className={style.icon} src={`${apiUrl}/${this.props.task.categorie.icon}`} alt={" "}/>}{this.state.trad.title} - {this.props.task.nbPointsGagnes}pts
                </h3>
                <p className={style.presentation}>{this.state.trad.description}</p>
                {this.isTooFar() && <p className={style.err}>{(localStorage.getItem("language") === "fr" ? `Vous êtes à ${Math.round(distance(this.props.task))}m de cette tâche` : `You are ${Math.round(distance(this.props.task))}m away from this task`)}</p>}
                {this.texte_completed && <p className={style.info}>{this.texte_completed}</p>}
                {this.texte_lieu_parent && <p className={style.err}>{this.texte_lieu_parent}</p>}
                {this.texte_tache_tempo && <p>{this.texte_tache_tempo}</p>}
                {(this.props.task.nbPointsMin > equipe.points && this.props.task.nbPointsMin > 0) && (
                    <p className={style.err}>{localStorage.getItem("language") === "fr"
                        ? `Il vous manque ${this.props.task.nbPointsMin - equipe.points} points pour répondre à cette tâche`
                        : `You lack ${this.props.task.nbPointsMin - equipe.points} points to answer this task`}</p>
                )}
                <button className={`${style.button} ${d ? button.disabled : ""}`} disabled={d} onClick={this.props.answer}>{localStorage.getItem("language") === "fr" ? "Répondre" : "Answer"}</button>
            </>
        );
    }
}