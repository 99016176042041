import {apiUrl, emit} from "../model";
import {Component} from "react";

/**
 * Affiche une image, une vidéo ou un audio
 */
export class Asset extends Component {
    constructor(props) {
        super(props);
        this.state = {open: false};
        this.handleClick = this.handleClick.bind(this);
    }

    /**
     * Affiche la photo en plein écran
     */
    handleClick() {
        if (this.props.blockClick) return;
        emit("photo", this.props.lien);
    }

    render() {
        this.type = Asset.getType(this.props.lien);
        if (this.type === "") return null;
        switch (this.type) {
            case 'audio':
                return(
                    <audio onPlay={() => {this.props.setPlaying(true)}} onPause={() => {this.props.setPlaying(false)}} controls={true}><source src={`${apiUrl}/${this.props.lien}`}/></audio>
                );
            case "video":
                return (<video onPlay={() => {this.props.setPlaying(true)}} onPause={() => {this.props.setPlaying(false)}} controls={true}><source src={`${apiUrl}/${this.props.lien}`}/></video>);
            case "image":
                return (<img onClick={this.handleClick} src={`${apiUrl}/${this.props.lien}`} alt={""}/>);
            default:
                return null;
        }
    }

    /**
     * Renvoie le type du lien
     * @param lien {string}
     * @return {"" | "image" | "audio" | "video"}
     */
    static getType(lien) {
        if (!lien) return '';
        //lien du type "vid.mp4"
        const ext = lien.split(".")[lien.split(".").length - 1];
        //not supported in Safari && can be audio or video
        if (["ogg", "ogv"].includes(ext)) return "";
        if (["mp3", "wav"].includes(ext)) return "audio";
        if (["mp4", "webm"].includes(ext)) return "video";
        return "image";
    }
}