import {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRotateRight, faLocationDot, faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import s from "./MapMenu.module.css";

/**
 * Affiche le menu de la carte gmaps
 */
export class MapMenu extends Component {
    render() {
        return (
            <>
                <div className={`${s.menu} ${s.m1}`}>
                    <button className={s.btn} onClick={this.props.reinit}><FontAwesomeIcon icon={faArrowRotateRight}/></button>
                    <button className={s.btn} onClick={this.props.center}><FontAwesomeIcon icon={faLocationDot} /></button>
                </div>
                <div className={`${s.menu} ${s.m2}`}>
                    <button className={s.btn} onClick={this.props.zoom}><FontAwesomeIcon icon={faPlus} /></button>
                    <button className={s.btn} onClick={this.props.unzoom}><FontAwesomeIcon icon={faMinus} /></button>
                </div>
            </>
        );
    }
}