import {apiUrl} from "../model";

/**
 * Joue un jingle de bonne ou mauvaise réponse
 * @param win {boolean}
 * true si c'est une bonne réponse, false sinon
 */
export function Jingle(win) {
    const audio = new Audio(`${apiUrl}/jingles/${win ? "win.mpeg" : "lose.mpeg"}`);
    audio.play();
}